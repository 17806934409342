import React, { useState, useRef } from "react";
import {
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { SimpleTableLayout } from "../../../components/layouts";
import { lastPathName, eliminarRegistro } from "../../../utilities";
import { ActionsButton, TablaComponent } from "../../../components";
import moment from "moment";

const Variables = () => {
  const endPoint = "acta-variable";
  let tablaRef = useRef(null);
  const { lastPath } = lastPathName();
  const history = useHistory();
  const [buscarValue, setBuscarValue] = useState("");

  const columns = [
    {
      title: "Acciones",
      key: "nombre",
      dataIndex: "nombre",
      width: 100,
      align: "center",
      render: (_, item) => (
        <ActionsButton
          options={[
            {
              name: "Editar",
              icon: <EditOutlined />,
              onClick: () =>
                history.push(`${lastPath}/detalle?nombre=${item.nombre}`),
            },
            {
              name: "Eliminar",
              icon: <DeleteOutlined />,
              onClick: () => {
                eliminarRegistro(item?.nombre, item?.nombre, endPoint, () =>
                  tablaRef?.current?.refresh()
                );
              },
              styleProps: {
                color: "#f5222d",
              },
            },
          ]}
        />
      ),
    },
    {
      title: "Nombre",
      key: "nombre",
      dataIndex: "nombre",
      render: (_, item) => (
        <Link to={`${lastPath}/detalle?nombre=${item.nombre}`}>
          {item?.nombre}
        </Link>
      ),
    },
    {
      title: "Descripción",
      key: "descripcion",
      dataIndex: "descripcion",
      render: (_, item) => (
        <Link to={`${lastPath}/detalle?nombre=${item.nombre}`}>
          {item?.descripcion}
        </Link>
      ),
    },
    {
      title: "Creado",
      key: "creado",
      dataIndex: "creado",
      render: (_, item) => (
        <Link to={`${lastPath}/detalle?nombre=${item.nombre}`}>
          {moment(item.creado).format("DD/MM/YYYY HH:mm")}
        </Link>
      ),
    },
  ];

  const buttonData = {
    text: "Nueva Variable",
    icon: <PlusOutlined />,
    to: () => history.push("/administracion/actas/variables/nueva"),
  };

  const onSearch = (search) => {
    setBuscarValue(search);
  };

  const breadcrumbItems = [
    {
      name: "Variables",
      to: "/administracion/actas/variables",
      icon: <FileTextOutlined />,
    },
  ];

  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      onSearchClicked={onSearch}
    >
      <TablaComponent
        nameURL={endPoint}
        columns={columns}
        order="nombre-desc"
        innerRef={tablaRef}
        scrollX="30vw"
        extraParams={{
          buscar: buscarValue,
        }}
      />
    </SimpleTableLayout>
  );
};

export default Variables;
