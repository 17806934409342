import React, {useMemo} from "react";
import {Button, ConfigProvider, Space, Tooltip} from "antd";
import {AuditOutlined, EyeOutlined, FilePdfOutlined, MailOutlined} from "@ant-design/icons";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import {useHistory} from "react-router-dom";
import moment from "moment"
import TablaComponent from "../../components/TablaComponent";
import locale from "antd/es/locale/es_ES";
import {useAuth} from "../../hooks";

const viewTitle = "Buzon";
const searchPlaceholder = "Buscar...";
const emptyText = "No hay Buzons registrados...";

const breadcrumbItems = [
  {
    name: "Buzón",
    to: "Buzon",
    icon: <AuditOutlined/>
  }
];

export const BuzonMunicipioLista = () => {
  const history = useHistory();
  const {user} = useAuth();

  const [search, setSearch] = React.useState("")
  const [searchLoading, setSearchLoading] = React.useState(false)

  const sinDefinir = <i>-Sin definir-</i>

  const renderSimple = (item, record) => {
    return (<a onClick={() => history.push(`buzon-municipios/detalle?id=${record?.id}`)}> {item} </a>);
  }

  const renderEstatus = (item, record) => {
    if (record.visto === null) {
      return <a onClick={() => history.push(`buzon-municipios/detalle?id=${record?.id}`)}>
        <MailOutlined style={{fontSize: "20px"}}/> Nuevo
      </a>;
    } else {
      return <a onClick={() => history.push(`buzon-municipios/detalle?id=${record?.id}`)}>
        {moment.utc(record.visto).local().format("DD/MM/YYYY HH:mm")}
      </a>;
    }
  }

  const columns = [
    {
      title: "Acciones",
      key: "operation",
      render: (_, record) => (
        <Space justify="space-between">
          <Tooltip title="Ver detalles">
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined/>}
              onClick={() => history.push(`buzon-municipios/detalle?id=${record?.id}`)}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Creado",
      dataIndex: "creado",
      key: "creado",
      fixed: "left",
      render: (prop, row) => renderSimple(prop ? moment(prop).format("DD/MM/YYYY") : sinDefinir, row)
    },
    {
      title: "Correo ",
      dataIndex: "email",
      key: "email",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      key: "telefono",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      fixed: "left",
      render: renderSimple
    },
  ];


  if (user.rol === "admin") {
    columns.push({
      title: "Ente ",
      dataIndex: "idEnte",
      key: "idEnte",
      fixed: "left",
      render: (_, row) => renderSimple(row?.ente?.descripcion || '---', row)
    })
    columns.push({
      title: "Visto",
      dataIndex: "visto",
      key: "visto",
      fixed: "left",
      render: (prop, row) => renderSimple(prop ? moment(prop).format("DD/MM/YYYY") : sinDefinir, row)
    })
  }

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    setSearch(v);
    setTimeout(() => setSearchLoading(false), 1500);
  }, []);


  const extraParams = useMemo(() => {
    return {q: search}
  }, [search]);

  return (
    <ConfigProvider locale={locale}>
      <SimpleTableLayout
        breadcrumbItems={breadcrumbItems}
        title={viewTitle}
        searchPlaceholder={searchPlaceholder}
        searchLoading={searchLoading}
        onSearchClicked={onSearch}
        emptyText={emptyText}
      >
        <TablaComponent
          nameURL={'buzon-municipio'}
          columns={columns}
          order={'id-desc'}
          expand={'ente'}
          extraParams={extraParams}
        />
      </SimpleTableLayout>
    </ConfigProvider>
  );
};
