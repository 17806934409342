import React, { useMemo } from "react";
import { Descriptions, Modal } from "antd";
import {
  EyeOutlined,
  ReconciliationOutlined,
  EditOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import { DropdownOverlayButton } from "../../../components";
import SimpleTableLayout from "../../../components/layouts/SimpleTableLayout";
import { useHistory } from "react-router-dom"
import TablaComponent from "../../../components/TablaComponent";

const viewTitle = "Categorías de Documentos";
const searchPlaceholder = "Buscar por título, descripción, etc.";
const emptyText = "No hay categorías registradas...";

export const CategoriaLista = () => {
  const history = useHistory()
  const [search, setSearch] = React.useState("")
  const [searchLoading, setSearchLoading] = React.useState(false)
  

  const breadcrumb=[
    {
      name: "Categorías de Documentos",
      to: "/administracion/categorias",
      icon: <ReconciliationOutlined />
    }
  ];

  const buttonData = {
    text: "Nueva Categoría",
    icon: <PlusCircleOutlined /> ,
    to: () => history.push("/administracion/categorias/nueva")
  }


  const sinDefinir = <i>-Sin definir-</i>

  const renderSimple = (prop) => prop || sinDefinir;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 20,
      render: renderSimple
    },
    {
      title: "Título",
      width: 80,
      dataIndex: "titulo",
      key: "titulo",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Descripción",
      width: 150,
      dataIndex: "descripcion",
      key: "descripcion",
      render: renderSimple
    },
    {
      title: "Ente",
      width: 150,
      dataIndex: "ente",
      key: "ente",
      render: prop => prop ? prop?.titulo : sinDefinir
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, row) => (
        <DropdownOverlayButton
          options={[
            {
              name: "Ver detalle",
              icon: <EyeOutlined />,
              onClick: () => modalInfo(row)
            },
            {
              name: "Editar información",
              icon: <EditOutlined />,
              onClick: () => history.push(`categorias/detalle?id=${row?.id}`),
              styleProps: {
                color: "#4495bb"
              }
            },
            // {
            //   name: "Eliminar",
            //   icon: <LockOutlined />,
            //   // onClick: () => modalDelete(row)
            //   styleProps: {
            //     color: "#f5222d"
            //   }
            // }
          ]}
        />
      ),
    },
  ];

  const ContentModal = ({row}) => {
    return (
      <div>
        <Descriptions
          bordered
          column={1}
          size="small"
          title="Información de la categoría"
        >
          <Descriptions.Item labelStyle={{"font-weight": "bold"}} label="ID">{row?.id || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"font-weight": "bold"}} label="Título">{row?.titulo || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"font-weight": "bold"}} label="Descripción">{row?.descripcion || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"font-weight": "bold"}} label="Ente">{row?.ente?.titulo || sinDefinir}</Descriptions.Item>
        </Descriptions>
      </div>
    )
  }

  const modalInfo = (v) => {

    Modal.confirm({
      title: "",
      icon: null,
      content: <ContentModal row={v}/>,
      okText: "Cerrar",
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { primary: true },
      closable: true,
      width:1000
    });
  };

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    setSearch(v);
    setSearchLoading(false);
  },[]);



  const extraParams = useMemo(() => {
    return {q: search}
  }, [search]);

  return (
    <SimpleTableLayout
      buttonData={buttonData}
      breadcrumbItems={breadcrumb}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearch}
      emptyText={emptyText}
    >
      <TablaComponent
        nameURL={'documento-categoria'}
        columns={columns}
        order={'id-desc'}
        extraParams={extraParams}
      />
    </SimpleTableLayout>
  );
};
