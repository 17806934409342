import React, {useState, useMemo, useCallback} from "react";
import { Modal, Row, Col, Button, Popover, Form, Input, Select, Space, Tooltip} from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  FileTextOutlined,
  PartitionOutlined,
  CloseCircleOutlined, SearchOutlined, ClearOutlined, FileExcelOutlined,
} from "@ant-design/icons";
import {useModels} from '../../../hooks';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import {useHistory} from 'react-router-dom';
import StatusResponse from '../../../services/statusResponse';
import {SimpleTableLayout} from '../../../components/layouts'
import moment from "moment";
import TablaComponent from "../../../components/TablaComponent";

const Permisos = () => {

  const titulo = 'Permisos';
  const url = '/administracion/permisos';
  const history = useHistory();
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [limite, setLimite] = useState(10);
  const {form} = Form.useForm();
  const [search, setSearch] = useState('');

  // const extraParamsMemo = useMemo(() => {
  //   let params = {}

  //   if (search && search.trim() !== '') {
  //     params.buscar = search.trim();
  //   }

  //   return params

  // }, [search]);


  const defaultText = prop => prop || <span style={{color: '#c7c3c3'}}>---</span>;

  // const [
  //   models,
  //   modelsLoading,
  //   // eslint-disable-next-line no-unused-vars
  //   modelsError,
  //   modelsPage,
  //   refreshModels
  // ] = useModels({
  //   name: 'permiso',
  //   ordenar: 'idPermisoModulo',
  //   expand: 'permisoModulo',
  //   extraParams: extraParamsMemo,
  //   limite: limite
  // });

  const breadcrumbItems = [
    {
      name: titulo,
      to: url,
      icon: <FileTextOutlined/>
    }
  ];

  const buttonData = {
    text: "Agregar",
    to: () => history.push(url + '/nuevo'),
    icon: <PlusOutlined/>,
    props: {disabled: false, type: 'primary'}
  };
  const buttonModuloData = {
    text: "AgregarModulo",
    to: () => history.push(url + '/modulo-nuevo'),
    icon: <PlusOutlined/>,
    props: {disabled: false, type: 'primary'}
  };

  const setRecrear = async () => {
    const res = await StatusResponse.delete("perfil/recrear-permisos");
    return new Promise((resolve, reject) => {
      try {
        if (res && res.status === 200) {
          console.log(res)
        } else if (res?.status === 400) {
          Modal.error({
            title: "Error: no se logro recrear los permisos",
            content: res?.mensaje,
          });
          reject();
        }
        reject();
      } catch (error) {
        console.log(error);
      }
    })
  }

  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={"Acción"}
          options={[
            {
              name: 'Editar',
              icon: <EditOutlined/>,
              onClick: () => history.push(`${url}/editar?id=${item.idPermiso}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined/>,
              onClick: () => modalEliminar(item),
              styleProps: {color: '#ff4d4f'}
            }
          ]}
        />
      )
    },
    {
      title: '# permiso',
      dataIndex: 'idPermiso',
      key: 'idPermiso',
      render: defaultText,
    },
    {
      title: 'Modulo',
      dataIndex: 'idModulo',
      key: 'idModulo',
      render: (_, item) => (<Popover
        content={<> {item?.permisoModulo?.descripcion}
          <Button
            onClick={() => history.push(url + '/modulo-editar?id=' + item.idPermisoModulo)}
            icon={<PlusOutlined/>}
            style={{width: "100%", minWidth:"80px", minHeight: "40px"}}
          >
            Editar Modulo
          </Button></>}> {item?.permisoModulo?.nombre} </Popover>),
    },
    {
      title: 'Nombre',
      dataIndex: 'permiso',
      key: 'permiso',
      render: defaultText,
    },
  ];

  const onSearch = async (searchString) => {
    setIsSearchLoading(true);
    setSearch(searchString);
    setIsSearchLoading(false);

    // setSearching(true);
    // let params = {};
    // if (search.length > 0) params = {...params, buscar: search};
    // params = {...params, pagina: 0};
    // await refreshModels(true, params);
    // if (!modelsLoading) setSearching(false);
  };

  // const onSetPageCallback = useCallback(
  //   async (page, size) => {
  //     setLimite(size);
  //     await refreshModels(true, {
  //       ordenar: 'idPermiso-desc',
  //       pagina: page,
  //       limite: size,
  //     })
  //   }, [refreshModels]
  // );

  // const configPagination = useMemo(() => {
  //   if (modelsPage) {
  //     let size = limite;

  //     return {
  //       total: modelsPage.total,
  //       pageSize: modelsPage.limite,
  //       onShowSizeChange: (_, newSize) => (size = newSize),
  //       onChange: async (v) => await onSetPageCallback(v, size),
  //       showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} permisos.`,
  //       locale: {items_per_page: '/ página'},
  //       pageSizeOptions: [10, 20, 30].filter(val => val <= modelsPage.total),
  //       showSizeChanger: true
  //     }
  //   }
  //   return null;
  // }, [onSetPageCallback, limite, modelsPage]);

  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{color: "red"}}/>,
      content: `¿Desea eliminar el permiso "${valor?.nombre}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("permiso/eliminar", {idPermiso: valor.idPermiso});
        return new Promise((resolve, reject) => {
          try {
            if (valor.idPermiso > 0) {
              if (res && res.status === 200) {
                // refreshModels(true, {
                //   ordenar: 'idPermiso-asc',
                //   pagina: 1,
                //   limite: limite
                // });
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error: no se logro eliminar el permiso",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  const onPrintExcel = async () => {

  }

  // const SearchForm = () => {

  //   const onFinishButton = async values => {
  //     let params = {}
  //     const {buscar} = values;

  //     if (buscar && buscar.trim() !== '') {
  //       setSearch(buscar.trim());
  //       params.buscar = buscar.trim();
  //     }
  //   }

  //   return (
  //     <Form
  //       form={form}
  //       layout="vertical"
  //       onFinish={onFinishButton}
  //     >
  //       <Row gutter={16}>
  //         <Col xs={24} sm={24} md={19} lg={19} xl={19} xxl={19}>
  //           <Form.Item
  //             label="Buscar: "
  //             name="buscar"
  //           >
  //             <Input
  //               disabled={modelsLoading}
  //               placeholder="BUSCAR..."
  //               defaultValue={search}
  //               value={search}
  //             />
  //           </Form.Item>
  //         </Col>


  //         <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
  //           <Space
  //             justify="space-between"
  //             style={{marginTop: '.5rem'}}
  //           >
  //             <Tooltip title="Buscar">
  //               <Button
  //                 icon={<SearchOutlined/>}
  //                 style={{marginTop: '1.5rem'}}
  //                 shape="circle"
  //                 type="primary"
  //                 // htmlType="submit"
  //                 loading={modelsLoading}
  //                 disabled={modelsLoading}
  //               />
  //             </Tooltip>

  //             <Tooltip title="Limpiar">
  //               <Button
  //                 icon={<ClearOutlined/>}
  //                 style={{marginTop: '1.5rem'}}
  //                 shape="circle"
  //                 type="default"
  //                 disabled={modelsLoading}
  //                 onClick={() => {
  //                   setSearch('');
  //                   refreshModels()
  //                 }}
  //               />
  //             </Tooltip>

  //             <Tooltip title="Recrear permisos de todos los usuarios">
  //               <Button
  //                 icon={<SearchOutlined/>}
  //                 style={{marginTop: '1.5rem'}}
  //                 shape="circle"
  //                 type="default"
  //                 disabled={modelsLoading}
  //                 onClick={() => {
  //                   setRecrear();
  //                 }}
  //               />
  //             </Tooltip>
  //           </Space>
  //         </Col>
  //       </Row>
  //     </Form>
  //   );
  // }


  const extraParams = useMemo(() => {
    return {q: search}
  }, [search]);

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      emptyText='Aún no hay permisos registrados.'
      searchLoading={isSearchLoading}
      onSearchClicked={onSearch}
    >
      <Row>
        {/* <Col
          xs={{span: 24, offset: 0}}
          sm={{span: 24, offset: 0}}
          md={{span: 16, offset: 0}}
          lg={{span: 16, offset: 0}}
          xxl={{span: 16, offset: 0}}
        >
          <SearchForm/>
        </Col> */}

        <Col
          xs={{span: 24, offset: 0}}
          sm={{span: 24, offset: 0}}
          md={{span: 4, offset: 0}}
          lg={{span: 4, offset: 0}}
          xxl={{span: 4, offset: 0}}
        >
          <Button
            onClick={() => history.push(url + '/modulo-nuevo')}
            icon={<PlusOutlined/>}
            style={{width: "100%", minWidth:"150px", minHeight: "40px"}}
            type="primary"
          >
            Agregar Módulo
          </Button>
        </Col>
        <Col
          xs={{span: 24, offset: 0}}
          sm={{span: 24, offset: 0}}
          md={{span: 4, offset: 0}}
          lg={{span: 4, offset: 0}}
          xxl={{span: 4, offset: 0}}
        >
          <Button
            onClick={() => history.push(url + '/nuevo')}
            icon={<PlusOutlined/>}
            style={{width: "100%", minWidth:"150px", minHeight: "40px"}}
            type="primary"
          >
            Agregar Permiso
          </Button>
        </Col>
        <Col span={24}>

          <TablaComponent 
            nameURL={'permiso'}
            columns={columns}
            order={'idPermisoModulo'}
            expand={'permisoModulo'}
            extraParams={extraParams}
          />
         
        </Col> 
      </Row>
    </SimpleTableLayout>
  )
}

export default Permisos