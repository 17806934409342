import React, {useEffect, useMemo} from 'react'
import {Row, Col, Input, Upload as AntdUpload} from 'antd'
import {DownloadOutlined} from '@ant-design/icons'
import {respuestas} from '../utilities'
import {useApp} from '../hooks'
import PropTypes from 'prop-types'
import ImgCrop from 'antd-img-crop'

const {REACT_APP_API_URL: baseUrl} = process.env;

const ImageUploader = ({
                         endPoint,
                         setReferencias,
                         setListaArchivos,
                         accept = 'image/*',
                         listaArchivos,
                         maxCount = null,
                         aspectHeight = 16,
                         aspectWidth = 16,
                         onRemove = undefined,
                       }) => {

  const {token} = useApp()

  const [lista, setLista] = React.useState([])

  const [height, setHeight] = React.useState(aspectHeight)
  const [width, setWidth] = React.useState(aspectWidth)
  const [aspect, setAspect] = React.useState(aspectWidth)

  const props = {
    name: 'file',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      size: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  }

  const onFinishSubir = ({fileList, file}) => {

    console.log(file)

    file.id = file.response.detalle.id
    file.url = file.response.detalle.ruta

    let listaReferencias = []
    let _listaArchivos = []
    fileList.forEach(file => {
      const res = {
        status: file?.xhr?.status,
        mensaje: file?.response?.mensaje
      }
      respuestas(res)
      _listaArchivos.push(file)
      if (file?.idMedia) {
        listaReferencias.push(file)
      } else {
        listaReferencias.push(file?.response?.detalle)
      }
    })
    setListaArchivos(_listaArchivos)
    setReferencias(listaReferencias)
  }

  const onChangeArchivos = (info) => {
    setLista(info.fileList)
    let fileList = info?.fileList
    let l = fileList.length
    let completo = false
    for (let i = 0; i < l; i++) {
      if (!fileList[i]?.id && !fileList[i]?.xhr) {
        completo = true
      } else {
        completo = false
      }
    }

    if (!completo) {
      onFinishSubir(info)
    }
  }

  React.useEffect(() => {
    if (listaArchivos) {
      setLista(listaArchivos.map(_archivo => {
        const _idMedia = _archivo?.response?.detalle?.idMedia || _archivo?.idMedia
        const _nombre = _archivo?.response?.detalle?.descripcion || _archivo?.descripcion
        return {
          ..._archivo,
          thumbUrl: _archivo?.ruta,
          idMedia: _idMedia,
          name: _nombre,
          url: `https://gestion.api.congresoson.gob.mx/publico/descarga?id=${_idMedia}`
        }
      }))
      return () => setLista([])
    }
  }, [listaArchivos])


  useEffect(() => {
    if (width || height)
      setAspect(width / height);
  }, [width, height])

  return (
    <ImgCrop
      showGrid
      rotationSlider
      aspectSlider={true}
      aspect={aspect}
      showReset
      modalTitle={<Row gutter={[10, 10]}>
        <Col span={24}>Editar Imagen</Col>
        {/*<Col span={24}>
          <Input value={width} disabled/>
        </Col>
        <Col span={24}>
          <Input value={height} disabled/></Col>*/}
      </Row>}
      modalFooter={"ret"}
      resetText="Reiniciar"
      fillColor="transparent"
      text={<Input/>}
    >
      <AntdUpload
        {...props}
        action={`${baseUrl}${endPoint}`}
        multiple={true}
        listType="picture-card"
        fileList={lista}
        onChange={onChangeArchivos}
        accept={accept}
        style={{width: '100% !importat'}}
        maxCount={maxCount}
        onRemove={onRemove}
      >
        <DownloadOutlined/> Subir Imagen
      </AntdUpload>
    </ImgCrop>
  )
}

ImageUploader.propTypes = {
  endPoint: PropTypes.string.isRequired,
  setReferencias: PropTypes.any.isRequired,
  setListaArchivos: PropTypes.any.isRequired,
  accept: PropTypes.string,
  listaArchivos: PropTypes.array.isRequired
}

export default ImageUploader