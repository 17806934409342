import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Layout, Menu, Row, Tooltip } from "antd";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useAuth } from "../../hooks";
import { dashboardRoutes } from "../../routers";
import "../../styles/DashboardLayout.less";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const rootSubmenuKeys = [""];

const DashboardLayout = ({ children: PanelContent }) => {
  const [state, setState] = React.useState({
    collapsed: false,
  });
  const history = useHistory();
  const [openKeys, setOpenKeys] = React.useState([""]);
  const [selectedKey, setSelectedKey] = React.useState("");
  const location = useLocation();
  const { user, userLoading, signOut } = useAuth();
  let finalDashboardRoutes = dashboardRoutes;

  //filtrado de paginas
  /*const roles ={
    "admin": dashboardRoutes,
    "transparencia": dashboardRoutes.filter(f =>
      f?.path.includes("buzon") ||
      f?.path.includes("denuncia") ||
      f?.path.includes("documentos") ||
      f?.path.includes("revision") ||
      f?.path.includes("solicitud-informacion") ||
      f?.path.includes("sujetos-obligados") ||
      f?.path.includes("organo-control") ||
      f?.path.includes("entes") ||
      f?.path.includes("administracion") ||
      f?.path.includes("mensajeria") ||
      f?.path.includes("transparencia")
    ),
    "municipio": dashboardRoutes.filter(f =>
      f?.path.includes("transparencia") ||
      f?.path.includes("documentos")
    ),
    "sindicato": dashboardRoutes.filter(f =>
      f?.path.includes("transparencia")
    ),
    "organismo-agua": dashboardRoutes.filter(f =>
      f?.path.includes("transparencia")
    )
  }

  finalDashboardRoutes = roles[user?.rol];*/

  React.useEffect(() => {
    const flatter = (r) =>
      r?.routes
        ? [
          r,
          ...r?.routes
            .map((sub) => ({ ...sub, path: r.path + sub.path }))
            .flatMap(flatter),
        ]
        : r;
    const flatted = dashboardRoutes.flatMap(flatter);
    const paths = flatted.map((r) => r.path);
    const key = paths.find((path) => path === location.pathname);
    // TODO: Hay algo extraño con las rutas base, revisar
    // const sub = key.substr(0, key.lastIndexOf("/"));
    // if (paths.some((p) => p.includes(sub))) {
    setSelectedKey(key);
    // }
    const tmpOpenKeys = flatted
      .filter(
        (r) => r.sidebar === "collapse" && location.pathname.includes(r.path)
      )
      .map((r) => "collapse-" + r.path);

    let _ret = collapsed ? [''] : tmpOpenKeys;
    setOpenKeys(_ret);
  }, [location]);

  const onCollapse = (collapsed) => {
    setState({ collapsed });
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };


  const sidebarMapper = (route) => {
    if (route.sidebar === "single") {
      return (
        <Menu.Item
          key={route.path}
          onClick={() => setSelectedKey(route.path)}
          icon={route.icon}
          hidden={
            route.rol ? !route?.rol.includes(user?.rol) : (route.ver ? !user?.permisoExtra?.includes(route.ver) : false)
          }
        >
          <NavLink to={route.path}>{route.name}</NavLink>
        </Menu.Item>
      );
    } else if (route.sidebar === "collapse") {
      const innerMap = (r) => ({ ...r, path: route.path + r.path });
      const finalKey = "collapse-" + route.path;
      return (
        <SubMenu
          key={finalKey}
          icon={route.icon}
          title={route.name}
          hidden={
            route.rol ? !route?.rol.includes(user?.rol) : (route.ver ? !user?.permisoExtra?.includes(route.ver) : false)
          }
        >
          {route?.routes?.map(innerMap).map(sidebarMapper)}
        </SubMenu>
      );
    }
    return null;
  };

  const { collapsed } = state;

  if (!user && userLoading) return null;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        id="auth-sider"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        theme="light"
        width={236}
        expandBehaviour="overTheTop"
      >
        {/*<img src={collapsed ? "/logo192.png" : "/logo192.png"} style={{padding: 10 }} width={ collapsed ? "40px" : "40px"} alt="Logo" />*/}
        <img src={collapsed ? "/logoCollapsed.png" : "/logo_istai_lg.png"} style={{ padding: 10 }} width={collapsed ? "100%" : "200px"} alt="Logo" />
        <Menu
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          className="sider-menu"
          selectedKeys={selectedKey}
          inlineCollapsed={collapsed}
          inlineIndent={18}
        >
          {dashboardRoutes?.map(sidebarMapper)}
          <Menu.Item
            key="single-/0"
            icon={<LogoutOutlined />}
            onClick={signOut}
          >
            Cerrar sesión
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row justify="space-between" style={{ padding: "0px 10px" }}>
            <Col>
            </Col>
            <Col>
              <Row>
                <Col>
                  <strong>{user?.nombre}</strong>
                </Col>
                <Col style={{ marginLeft: 10 }}>
                  <Tooltip title="Ir a Perfil">
                    <Avatar
                      size={42}
                      icon={<UserOutlined />}
                      onClick={() => {
                        history.push("/perfil");
                      }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
        <Content>{PanelContent}</Content>
        <Footer style={{ textAlign: "center" }}>Derechos reservados {new Date().getFullYear()} &#xa9; - v1.0.1.2</Footer>
      </Layout>

    </Layout>
  );
};

export default DashboardLayout;