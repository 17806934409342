import {
  AuditOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {Modal, Table, Tag, Tooltip} from "antd";
import moment from "moment";
import React from "react";
import {Link, useHistory} from "react-router-dom";
import {ActionsButton} from "../../components";
import TablaComponent from "../../components/TablaComponent";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import StatusResponse from "../../services/statusResponse";
import {useAuth} from "../../hooks";

const permisoCrearActa = 24;
const permisoEditarActa = 26;
const permisoEliminarActa = 25;

const Actas = () => {
  const {REACT_APP_API_URL: baseUrl} = process.env;

  const {user} = useAuth();

  const crear = user?.permisoExtra?.includes(permisoCrearActa);
  const eliminar = user?.permisoExtra?.includes(permisoEliminarActa);
  const editar = user?.permisoExtra?.includes(permisoEditarActa);

  const _baseUrl = baseUrl.replace("v1/", "");

  const tablaRef = React.useRef(null);

  const history = useHistory();

  const [buscar, setBuscar] = React.useState("");

  const breadcrumb = [
    {
      name: "Actas",
      to: "/actas-sesion",
      icon: <AuditOutlined/>,
    },
  ];

  const buttonData = {
    text: "Nueva Acta",
    icon: <PlusCircleOutlined/>,
    to: () => history.push("actas-sesion/agregar"),
  };

  const onSearch = (v) => {
    setBuscar(v);
  };

  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{color: "red"}}/>,
      content: `¿Desea eliminar el acta "${valor?.titulo}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("acta/eliminar", {
          id: valor.id,
        });
        return new Promise((resolve, reject) => {
          try {
            if (valor.id > 0) {
              if (res && res.status === 200) {
                tablaRef?.current?.refresh();
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        });
      },
    });
  };

  const columns = [
    {
      title: "Acciones",
      key: "id",
      dataIndex: "id",
      render: (_, item) => (
        <ActionsButton
          options={[
            {
              name: "Editar",
              onClick: () => history.push(`actas-sesion/detalle?id=${item.id}`),
              hidden: !editar,
            },
            {
              name: "Calendario de Sesión",
              onClick: () =>
                history.push(`actas-sesion/calendario-sesion?id=${item.id}`),
            },
            {
              name: "Imprimir Acta",
              onClick: () =>
                window.open(`${_baseUrl}word/acta?id=${item.id}`, "_blank"),
            },
            {
              name: "SCRIPT de Sesión",
              onClick: () =>
                history.push(`actas-sesion/script-sesion?id=${item.id}`),
            },
            {
              name: "Imprimir SCRIPT de Sesión",
              onClick: () =>
                window.open(
                  `${_baseUrl}word/acta/auxiliar-sesion-por-seccion?id=${item.id}`,
                  "_blank"
                ),
            },
            {
              name: "Recursos y/o Denuncias",
              onClick: () =>
                history.push(`actas-sesion/acta-recursos?idActa=${item.id}`),
            },
            {
              name: "Eliminar",
              onClick: () => modalEliminar(item),
              danger: true,
              styleProps: {
                color: "#f5222d",
              },
              hidden: !eliminar,
            },
          ]}
        />
      ),
    },
    {
      title: "Fecha",
      key: "fecha",
      dataIndex: "fecha",
      render: (_, item) => (
        <Link to={`actas-sesion/detalle?id=${item?.id}`}>
          {moment(item?.fecha)?.format("DD/MM/YYYY")}
        </Link>
      ),
    },
    {
      title: "Título",
      key: "titulo",
      dataIndex: "titulo",
      render: (_, item) => (
        <Link to={`actas-sesion/detalle?id=${item?.id}`}>{item?.titulo}</Link>
      ),
    },
    {
      title: "Expedientes",
      key: "casos",
      dataIndex: "casos",
      render: (_, item) => (
        <Link to={`actas-sesion/detalle?id=${item?.id}`}>
          {item?.casos?.length}
        </Link>
      ),
    },
    {
      title: "Estatus",
      key: "estatus",
      dataIndex: "estatus",
      render: (_, item) => (
        <Link to={`actas-sesion/detalle?id=${item?.id}`}>
          {
            item?.estatus === "ABIERTO" ?
              <Tag color="green">{item?.estatus}</Tag>
              :
              <Tag color="red">{item?.estatus}</Tag>
          }
        </Link>
      ),
    },
  ];

  const expandableMemo = React.useMemo(
    () => ({
      defaultExpandAllRows: true,
      expandedRowRender: (row) => {
        const columnas = [
          {
            title: "No. Expediente",
            key: "numeroExpediente",
            dataIndex: "numeroExpediente",
            render: (_, item) => (
              <Link
                to={`/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`}
              >
                {item?.tipo === "RECURSO"
                  ? `ISTAI-RR-${item?.numeroExpediente}`
                  : `ISTAI-DI-${item?.numeroExpediente}`}
              </Link>
            ),
          },
          {
            title: "Tipo de Recurso",
            key: "tipo",
            dataIndex: "tipo",
            render: (_, item) => (
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(
                    `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
                  );
                }}
              >
                <Tooltip title={item?.tipo}>
                  <Tag color={item?.tipo === "RECURSO" ? "#0070c1" : "#6f2fa0"}>
                    {item?.tipo}
                  </Tag>
                </Tooltip>
              </Link>
            ),
          },
          {
            title: "Ponencia",
            key: "idPonencia",
            dataIndex: "idPonencia",
            render: (_, item) => (
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(
                    `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
                  );
                }}
              >
                <Tooltip title={item?.ponencia?.nombre}>
                  <Tag color="#00736c">{item?.ponencia?.clave}</Tag>
                </Tooltip>
              </Link>
            ),
          },
          {
            title: "Sujeto Obligado",
            key: "sujetoObligado",
            dataIndex: "sujetoObligado",
            render: (_, item) => (
              <Link
                to={`/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`}
              >
                {item?.tipo === "RECURSO"
                  ? item?.sujetoObligado
                  : item?.sujetoDenunciado}
              </Link>
            ),
          },
          {
            title: "Recurrente",
            key: "recurrente",
            dataIndex: "recurrente",
            render: (_, item) => (
              <Link
                to={`/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`}
              >
                {item?.tipo === "RECURSO" ? item?.recurrente : item?.denunciante}
              </Link>
            ),
          },
          {
            title: "Fecha de Solicitud",
            key: "fechaSolicitud",
            dataIndex: "fechaSolicitud",
            render: (_, item) => (
              <Link
                to={`/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`}
              >
                {moment(item?.fechaSolicitud)?.format("DD/MM/YYYY")}
              </Link>
            ),
          },
          {
            title: "Solicita",
            key: "solicita",
            dataIndex: "solicita",
            render: (_, item) => (
              <Link
                to={`/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`}
              >
                <Tooltip title={item?.solicita}>
                  {item?.tipo === "RECURSO"
                    ? `${item?.solicita?.substring(0, 40)}...`
                    : `${item?.denuncia?.substring(0, 40)}...`}
                </Tooltip>
              </Link>
            ),
          },
          {
            title: "Fecha del Recurso",
            key: "fechaRecurso",
            dataIndex: "fechaRecurso",
            render: (_, item) => (
              <Link
                to={`/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`}
              >
                {item.tipo === "RECURSO"
                  ? moment(item?.fechaRecurso)?.format("DD/MM/YYYY")
                  : "No aplica"}
                ,
              </Link>
            ),
          },
          {
            title: "Litis",
            key: "litis",
            dataIndex: "litis",
            render: (_, item) => (
              <Link
                to={`/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`}
              >
                <Tooltip title={item?.litis}>
                  {`${item?.litis?.substring(0, 40)}...`}
                </Tooltip>
              </Link>
            ),
          },
          {
            title: "Informe Justificado",
            key: "respuesta",
            dataIndex: "respuesta",
            render: (_, item) => (
              <Link
                to={`/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`}
              >
                <Tooltip title={item?.respuesta}>
                  {item?.respuesta
                    ? `${item?.respuesta?.substring(0, 40)}...`
                    : ""}
                </Tooltip>
              </Link>
            ),
          },
          {
            title: "Sentido",
            key: "sentido",
            dataIndex: "sentido",
            render: (_, item) => (
              <Link
                to={`/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`}
              >
                <Tooltip title={item?.sentido}>
                  {`${item?.sentido?.substring(0, 40)}...`}
                </Tooltip>
              </Link>
            ),
          },
          {
            title: "Informe Verificación",
            key: "informeVerificacion",
            dataIndex: "informeVerificacion",
            render: (_, item) => (
              <Link
                to={`/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`}
              >
                {item?.tipo === "RECURSO"
                  ? "No aplica"
                  : <Tooltip title={item?.informeVerificacion}>
                    {`${item?.informeVerificacion?.substring(0, 40)}...`}
                  </Tooltip>
                }
              </Link>
            ),
          },
        ];

        return (
          <Table
            columns={columnas}
            dataSource={row?.casos}
            pagination={false}
            rowKey="id"
            size="small"
          />
        );
      },
    }),
    []
  );

  return (
    <SimpleTableLayout
      buttonData={crear ? buttonData : null}
      breadcrumbItems={breadcrumb}
      title={"Actas Sesión"}
      onSearchClicked={onSearch}
    >
      <TablaComponent
        nameURL={"acta"}
        columns={columns}
        order={"id-desc"}
        expand="casos.ponencia"
        extraParams={{q: buscar}}
        innerRef={tablaRef}
        expandable={expandableMemo}
      />
    </SimpleTableLayout>
  );
};

export default Actas;
