import React from "react";
import {Upload as AntdUpload, Modal} from "antd";
import {DownloadOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {useApp} from "../hooks";
import PropTypes from "prop-types"
import {Respuestas} from "../utilities/Respuesta";
import StatusResponse from "../services/statusResponse";

const {REACT_APP_API_URL: baseUrl} = process.env;

const Uploader = ({
                    endPoint,
                    setReferencias,
                    setListaArchivos,
                    accept = "*",
                    listaArchivos,
                    endPointEliminar,
                    archivoMaximo = 1
                  }) => {

  const {token} = useApp();

  const [lista, setLista] = React.useState([]);

  const props = {
    name: 'archivo',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const onFinishSubir = ({fileList}) => {
    let listaReferencias = [];
    let _listaArchivos = [];
    fileList.forEach(file => {
      const res = {
        status: file?.xhr?.status,
        mensaje: file?.response?.mensaje
      }
      Respuestas(res);
      _listaArchivos.push(file);
      if (file?.id) {
        listaReferencias.push(file);
      } else {
        listaReferencias.push(file?.response?.detalle);
      }
    });
    setListaArchivos(_listaArchivos);
    setReferencias(listaReferencias);
  };

  const onChangeArchivos = (info) => {
    setLista(info.fileList)
    let fileList = info?.fileList;
    let l = fileList.length;
    let completo = true;
    for (let i = 0; i < l; i++) {
      if (!fileList[i]?.id && !fileList[i]?.xhr) {
        completo = false;
      }
    }
    if (completo) {
      onFinishSubir(info)
    }
  };

  const eliminarArchivo = async (v) => {

    let body = {
      id: v.id
    }

    const res = await StatusResponse.delete(endPointEliminar ? endPointEliminar : 'sujetos-obligados-archivo/eliminar', body);
    Respuestas(res);
    setListaArchivos((prev) => {
      const index = prev.map((item) => item.id).indexOf(v.id);
      prev.splice(index, 1);
      return [...prev];
    })
  }

  const showDeleteConfirm = (v) => {
    Modal.confirm({
      title: 'Eliminar documento',
      icon: <ExclamationCircleOutlined/>,
      content: `¿Seguro que desea eliminar el documento ${v.nombre}?`,
      okText: 'Sí',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        eliminarArchivo(v);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
    return false;
  };

  React.useEffect(() => {
    if (listaArchivos) {
      setLista(listaArchivos.map(_archivo => {
        console.log(_archivo)
        const _idMedia = _archivo?.response?.detalle?.idMedia || _archivo?.idMedia
        const _nombre = _archivo?.response?.detalle?.descripcion || _archivo?.descripcion
        return {
          ..._archivo,
          thumbUrl: _archivo?.ruta,
          idMedia: _idMedia,
          name: _nombre,
          url:  _archivo?.ruta
          //url: `https://gestion.api.congresoson.gob.mx/publico/descarga?id=${_idMedia}`
        }
      }))
      return () => setLista([]);
    }
  }, [listaArchivos])

  return (
    <AntdUpload
      {...props}
      onRemove={(v) => showDeleteConfirm(v)}
      action={`${baseUrl}${endPoint}`}
      multiple={true}
      listType="picture-card"
      fileList={lista}
      onChange={onChangeArchivos}
      accept={accept}
      style={{width: "100% !importat"}}
    >
      {lista.length < archivoMaximo ? <><DownloadOutlined/> Subir Archivo</> : ""}
    </AntdUpload>
  )
}

Uploader.propTypes = {
  endPoint: PropTypes.string.isRequired,
  setReferencias: PropTypes.any.isRequired,
  setListaArchivos: PropTypes.any.isRequired,
  accept: PropTypes.string,
  listaArchivos: PropTypes.array.isRequired
}

export default Uploader;