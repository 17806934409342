import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {AutoComplete, Tag} from 'antd'
import {useModels} from '../hooks'

const InputAutoComplete = ({
                             modelsParams,
                             labelProp,
                             valueProp,
                             render,
                             append,
                             notIn,
                             deleteSelected,
                             extraParams,
                             placeholder = 'Introduce texto...',
                             ...props
                           }) => {

  const [request, setRequest] = React.useState({})
  const [buscarValue, setBuscarValue] = React.useState('')
  const [timer, setTimer] = React.useState(null)
  const [notInState, setNotIn] = React.useState('')
  const [value, setValue] = useState('')

  const extraParamsMemo = React.useMemo(
    () => ({
      buscar: buscarValue,
      notIn: notInState,
      campo: labelProp,
      ...extraParams
    }),
    [buscarValue, extraParams, notInState]
  )

  const requestMemo = React.useMemo(() => ({
    name: modelsParams?.name || '',
    ordenar: modelsParams?.ordenar || `${labelProp}-desc`,
    limite: modelsParams?.limite || 20,
    expand: modelsParams?.expand || '',
    extraParams: extraParamsMemo,
  }), [extraParamsMemo, modelsParams])

  const {
    models,
    modelsLoading,
    modelsError
  } = useModels(request)

  const onSearch = (value) => {
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      setBuscarValue(value)
    }, 300)

    setTimer(newTimer)
  }

  const quitarDuplicados = (string) => {
    if (!string) return
    const arr = String(string).split(',') || []
    const sinDuplicados = arr.filter((item, index) => arr.indexOf(item) === index)
    return sinDuplicados.join(',')
  }

  if (!render) {
    render = (value) => value
  }

  if (!append) {
    append = []
  }

  const onDeselect = React.useCallback((labeledValue) => {
    if (!labeledValue && !notIn) return
    setNotIn(lastState => {
      const sinDuplicados = quitarDuplicados(
        lastState?.length
          ? lastState += `,${labeledValue}`
          : labeledValue
      ).split(',')
      return sinDuplicados.filter(f => f !== String(labeledValue)).join(',') || ''
    })
  }, [notIn])

  React.useEffect(() => {
    setRequest(requestMemo)
    return () => {
      setRequest({})
    }
  }, [requestMemo])

  React.useEffect(() => {
    if (notIn) {
      setNotIn(lastState => {
        const sinDuplicados = quitarDuplicados(
          lastState?.length
            ? lastState += `,${notIn}`
            : notIn
        ).split(',')
        return sinDuplicados.join(',') || ''
      })
    }
  }, [notIn])

  const onChange = (data) => {
    setValue(data)
  }

  React.useEffect(() => {
    if (deleteSelected) {
      onDeselect(deleteSelected)
    }
  }, [deleteSelected, onDeselect])

  if (modelsError) {
    return <Tag color="red">error al obtener información de selector.</Tag>
  }

  return (

    <AutoComplete
      {...props}
      options={models?.map(item => ({value: item}))}
      onSearch={onSearch}
      placeholder={placeholder}
    />
  )
}

AutoComplete.propTypes = {
  modelsParams: PropTypes.object.isRequired,
  labelProp: PropTypes.string.isRequired,
  valueProp: PropTypes.string.isRequired,
}

export default InputAutoComplete