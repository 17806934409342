import React, { useState, useEffect, useMemo } from "react";
import { Form, Row, Col, Input, Button, notification, Tooltip, Typography, Select } from "antd";
import { PlusCircleOutlined, FileWordOutlined, EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { respuestas } from "../../../utilities";
import { useModel, useQuery } from "../../../hooks";
import { CopyOutlined, SaveOutlined } from "@ant-design/icons";
import { DefaultLayout } from "../../../components/layouts";
import HttpService from "../../../services/httpService";
import { Divider, EditorTexto, TablaComponent } from "../../../components";

const { Title } = Typography;
const { Search } = Input;

const FormatoDetalle = () => {

  const endPoint = "acta-formato";
  const [form] = Form.useForm();
  const history = useHistory();
  const query = useQuery();
  const idFormatoActa = query.get("id");
  const editando = Boolean(idFormatoActa);

  const [request, setRequest] = useState({});
  const [buscarValue, setBuscarValue] = useState('');

  const breadcrumbItems = [
    {
      name: "Formatos de Actas",
      to: "/administracion/actas/formatos",
      icon: <FileWordOutlined />
    },
    {
      name: editando ? "Editar" : "Agregar",
      to: editando ? `/administracion/actas/formatos/detalle?id=${idFormatoActa}` : `/administracion/actas/formatos/nuevo`,
      icon: editando ? <EditOutlined /> : <PlusCircleOutlined />
    }
  ];

  const requestParams = useMemo(
    () => ({
      name: endPoint,
      id: idFormatoActa,
    }),
    [idFormatoActa]
  );

  const { model } = useModel(request);

  const columns = [
    {
      title: "Copiar",
      width: 10,
      align: 'center',
      render: (_, item) => (
        <Button
          icon={<CopyOutlined />}
          onClick={() => {
            navigator.clipboard.writeText(item.nombre);
            notification.success({
              message: 'Copiado!',
              description: `Se ha copiado ${item?.nombre} en el portapaleles`
            })
          }}
        />
      )
    },
    {
      title: "Nombre",
      key: "nombre",
      dataIndex: "nombre",
      render: (_, item) => (
        <Tooltip
          title={item?.descripcion}
          placement="left"
        >
          {item?.nombre}
        </Tooltip>
      )
    },

  ];

  const onSearch = (search) => {
    setBuscarValue(search);
  };

  const onFinish = async (values) => {
    try {

      const { cuerpo } = values;
      const _cuerpo = cuerpo.replace('<p class="ql-align-justify"><br></p><p class="ql-align-justify"><br></p>', '<p class="ql-align-justify"><br></p>');

      let body = {
        ...values,
        cuerpo: _cuerpo,
        id: idFormatoActa,
      };

      const resp = await HttpService.post(endPoint, body);

      respuestas(resp);

      if (resp?.mensaje !== 'Formato guardado.') {
        console.log('resp', resp);
      }

    } catch (error) {
      console.log("error al guardar: ", error);
    } finally {
      history.push('/administracion/actas/formatos');
    }
  };

  // Request models
  useEffect(() => {
    setRequest(requestParams);
    return () => setRequest({});
  }, [requestParams]);

  // Setear Formulario
  useEffect(() => {
    if (!editando && !model) return;
    form.setFieldsValue({
      ...model,
    });
  }, [editando, form, model]);


  return (
    <DefaultLayout
      breadcrumbItems={breadcrumbItems}
    >
      <Form form={form} name="form" layout="vertical" onFinish={onFinish}>

        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Form.Item
              label="Nombre del formato"
              name="nombre"
              rules={[{ required: true, message: 'Ingresa un nombre' }]}
            >
              <Input placeholder="Escriba un nombre" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Tipo de formato"
              name="tipo"
            >
              <Select
                style={{ width: '100%' }}
                allowClear={true}
                showSearch
                placeholder="Seleccione un formato"
                options={[
                  { label: 'Acta de sesión', value: 'Acta de sesión' },
                ]}
              />
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={[10, 10]}>
          <Col span={16}>
            <Form.Item
              label="Descripción del formato"
              name="descripcion"
            >
              <Input.TextArea rows={4} placeholder="Ingrese una descripción del formato..." />
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={[10, 10]}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 16 }}
            lg={{ span: 18 }}
          >
            <Form.Item name="cuerpo">

              <EditorTexto />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 6 }}
          >
            <Title level={4}>Listado de variables disponibles</Title>
            <Search
              placeholder="Buscar variable"
              onSearch={onSearch}
              enterButton
            />
            <Divider my={10} />
            <TablaComponent
              columns={columns}
              scrollX='10vw'
              nameURL={"acta-variable"}
              extraParams={{ buscar: buscarValue || '', ordenar: 'nombre-asc' }}
            />
          </Col>
        </Row>

        <Divider my={10} />

        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Form.Item>
              <Button
                icon={<SaveOutlined />}
                type="primary"
                block
                htmlType="submit"
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DefaultLayout>
  );
};

export default FormatoDetalle;
