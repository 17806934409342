import React from 'react';
import { Button, Menu, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

const ActionsButton = ({ options = [] }) => {
  return (
    <Dropdown
      overlay={
        <Menu>
          {options.map((item, i) => (
            <Menu.Item
              key={i}
              icon={item.icon}
              onClick={item.onClick}
              style={item.styleProps}
              {...item.props}
              hidden={item.hidden}
            >
              {item.name}
            </Menu.Item>
          ))}
        </Menu>
      }
      placement="top"
    >
      <Button>
        Acción <MoreOutlined />
      </Button>
    </Dropdown>
  );
};

export default ActionsButton;