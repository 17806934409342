import {AuditOutlined, CopyOutlined} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Divider,
  Row,
  Tooltip,
  Typography,
  notification,
  Select as SelectAntd, Collapse,
} from "antd";
import locale from "antd/lib/date-picker/locale/es_ES";
import moment from "moment";
import React, {useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {
  EditorTexto,
  Select,
  TablaComponent, Uploader,
  ViewLoading,
} from "../../components";
import {DefaultLayout} from "../../components/layouts";
import {useAuth, useModel, useModels, useQuery} from "../../hooks";
import StatusResponse from "../../services/statusResponse";
import {Respuesta} from "../../utilities/Respuesta";

const permisoEditarActa = 26;
const permisoCrearActa = 24;
const permisoAbrirCerrarRecursos = 29;

const ActaCalendario = () => {
  const history = useHistory();
  const q = useQuery();

  const id = q.get("id");
  const editando = Boolean(id);
  const media = 'mediaActa';

  const [form] = Form.useForm();

  const [request, setRequest] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [request2, setRequest2] = useState({});

  const [models] = useModels(request2);

  const {user} = useAuth();

  const crear = user?.permisoExtra?.includes(permisoCrearActa);
  const editar = user?.permisoExtra?.includes(permisoEditarActa);
  const abrirCerrar = user?.permisoExtra?.includes(permisoAbrirCerrarRecursos);

  const [listaArchivosVersion, setListaArchivosVersion] = useState([]);
  const [referenciasVersion, setReferenciasVersion] = useState([]);

  const [listaArchivosActa, setListaArchivosActa] = useState([]);
  const [referenciasActa, setReferenciasActa] = useState([]);

  const requestParams = useMemo(
    () => ({
      name: "acta",
      id: id,
      expand: "actaFormato, mediaActa",
    }),
    [id]
  );

  const {model, modelLoading} = useModel(request);

  const breadcrumb = [
    {
      name: "Actas Sesión",
      to: "/actas-sesion",
      icon: <AuditOutlined/>,
    },
    {
      name: editando ? `${model?.titulo}` : "Nueva",
      to: "/calendario-sesion",
      icon: <AuditOutlined/>,
    },
  ];


  const onFinish = async (values) => {
    try {
      setSaveLoading(true);

      let body = {
        ...values,
        Version: referenciasVersion,
        Acta: referenciasActa
      };

      if (editando) {
        body.id = id;
      }

      const res = await StatusResponse.post("acta/guardar", body);
      Respuesta(res);
      if (res?.status === 200) {
        history.push("/actas-sesion/calendario-sesion");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setRequest(requestParams);
    setRequest2({
      name: "acta-formato",
      limit: -1,
    });
    return () => {
      setRequest({});
      setRequest2({});
    };
  }, [requestParams]);

  useEffect(() => {
    if (editando && model) {
      console.log(model)
      form.setFieldsValue({
        ...model,
        fecha: model?.fecha ? moment(model?.fecha) : null,
      });

      setListaArchivosActa(model[media]?.filter(item => item?.tipo === 'ACTA')
        .filter(item => item?.media !== null)
        .map(media => media.media))

      setReferenciasActa(model[media]?.filter(item => item?.tipo === 'ACTA')
        .filter(item => item?.media !== null)
        .map(media => media.media))

      setListaArchivosVersion(model[media]?.filter(item => item?.tipo === 'VERSION')
        .filter(item => item?.media !== null)
        .map(media => media.media))

      setListaArchivosVersion(model[media]?.filter(item => item?.tipo === 'VERSION')
        .filter(item => item?.media !== null)
        .map(media => media.media))

    }
  }, [editando, form, model]);


  if (modelLoading) return <ViewLoading/>;

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editando ? `Acta ${model?.titulo}` : "Nueva Acta de Sesión"}
    >
      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={10}>
          <Col sm={12} md={12}>
            <Form.Item label="Fecha" name="fecha">
              <DatePicker
                style={{width: "100%"}}
                format="DD/MM/YYYY HH:mm"
                placeholder="Selecciona la fecha"
                locale={locale}
                showTime
                disabled
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={12}>
            <Form.Item label="ID de Video de Youtube" name="videoYoutube">
              <Input/>
            </Form.Item>
          </Col>
        </Row>
        <Divider my={10}/>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Divider>Versión estenográfica</Divider>
          </Col>
          <Uploader
            endPoint={"subir-archivo"}
            endPointEliminar={"media/eliminar"}
            setReferencias={setReferenciasVersion}
            setListaArchivos={setListaArchivosVersion}
            listaArchivos={listaArchivosVersion}
          />
          <Divider>Acta</Divider>
          <Uploader
            endPoint={"subir-archivo"}
            endPointEliminar={"media/eliminar"}
            setReferencias={setReferenciasActa}
            setListaArchivos={setListaArchivosActa}
            listaArchivos={listaArchivosActa}
          />
        </Row>
        <Divider my={10}/>
        <Row gutter={[16, 0]} style={{marginTop: "10px"}}>
          {((!editando && crear) || (editando && editar)) && (
            <Col sm={6}>
              <Form.Item>
                <Button htmlType="submit" loading={saveLoading} type="primary">
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </DefaultLayout>
  );
};

export default ActaCalendario;
