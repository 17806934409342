import React from "react";

import {emptyRequest, getRequest, postRequest} from "../constants/requests";
import {useHttp} from "./useHttp";
import {useApp} from "./useApp";

const AuthContext = React.createContext();

const empty = emptyRequest();

export function AuthProvider(props) {
  const {token, setToken} = useApp();
  const [sessionRequest, setSessionRequest] = React.useState(empty);
  const [session, sessionLoading] = useHttp(sessionRequest);
  const [agendaRequest, setAgendaRequest] = React.useState(empty);
  const [agendaResponse, agendaResponseLoading] = useHttp(agendaRequest);

  React.useEffect(() => {
    if (session && !sessionLoading) {
      if (session?.detalle) {
        const {token} = session.detalle;
        setToken(token);
      }
    }
  }, [session, sessionLoading, setToken]);

  React.useEffect(() => {
    if (token) {
      const agendaReq = getRequest("perfil", {
        expand: "",
      });
      setAgendaRequest(() => agendaReq);
    } else {
      setAgendaRequest(() => empty);
    }
  }, [token]);

  const signIn = React.useCallback(async (email, password) => {
    try {
      if (email !== "" && password !== "") {
        const req = postRequest("iniciar-sesion", {correo: email, clave: password});
        setSessionRequest({...req});
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const signOut = React.useCallback(async () => {
    try {
      setToken(null);
      setSessionRequest(empty);
    } catch (e) {
      console.log(e);
    }
  }, [setToken]);

  const memData = React.useMemo(() => {
    return {
      session,
      sessionLoading,
      user:
        agendaResponse &&
        agendaResponse?.detalle
          ? {
            ...agendaResponse.detalle.usuario,
            permisoExtra: agendaResponse.detalle.permisos,
            enteUsuario: agendaResponse.detalle.enteUsuario,
          }
          : null,
      userLoading: agendaResponseLoading,
      signIn,
      signOut,
    };
  }, [
    session,
    sessionLoading,
    agendaResponse,
    agendaResponseLoading,
    signIn,
    signOut,
  ]);


  return <AuthContext.Provider value={memData} {...props} />;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: auth context not defined.";
  }
  return context;
}