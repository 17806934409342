import React, { useState, useRef } from 'react'
import { PlusOutlined, FileWordOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useHistory, Link } from 'react-router-dom';
import { SimpleTableLayout } from '../../../components/layouts';
import { eliminarRegistro, lastPathName } from '../../../utilities';
import { ActionsButton, TablaComponent } from '../../../components';
import moment from 'moment';


const Formatos = () => {

  const endPoint = "acta-formato";
  let tablaRef = useRef(null);
  const { lastPath } = lastPathName();
  const history = useHistory();
  const [buscarValue, setBuscarValue] = useState('');

  const columns = [
    {
      title: "Acciones",
      key: "id",
      dataIndex: "id",
      width: 100,
      align: "center",
      render: (_, item) => (
        <ActionsButton
          options={[
            {
              name: "Editar",
              icon: <EditOutlined />,
              onClick: () => history.push(`${lastPath}/detalle?id=${item.id}`),
            },
            {
              name: "Eliminar",
              icon: <DeleteOutlined />,
              onClick: () => {
                eliminarRegistro(
                  `${item.nombre}`, item.id, endPoint,
                  () => tablaRef?.current?.refresh()
                )
              },
              styleProps: {
                color: "#f5222d"
              }
            },
          ]}
        />
      ),
    },
    {
      title: "Nombre",
      key: "nombre",
      dataIndex: "nombre",
      render: (_, item) => (
        <Link to={`${lastPath}/detalle?id=${item.id}`}>
          {item?.nombre}
        </Link>
      )
    },
    {
      title: "Tipo",
      key: "tipo",
      dataIndex: "tipo",
      render: (_, item) => (
        <Link to={`${lastPath}/detalle?id=${item.id}`}>
          {item?.tipo}
        </Link>
      )
    },
    {
      title: "Creado",
      key: "reado",
      dataIndex: "creado",
      render: (_, item) => (
        <Link to={`${lastPath}/detalle?id=${item.id}`}>
          {moment(item.creado).format('DD/MM/YYYY HH:mm')}
        </Link>
      )
    },
  ];

  const buttonData = {
    text: "Nuevo Formato",
    icon: <PlusOutlined /> ,
    to: () => history.push("/administracion/actas/formatos/nuevo")
  }

  const onSearch = (search) => {
    setBuscarValue(search);
  };

  const breadcrumbItems = [
    {
      name: "Formatos de Actas",
      to: "/administracion/actas/formatos",
      icon: <FileWordOutlined />,
    },
  ];

  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      onSearchClicked={onSearch}
    >
      <TablaComponent 
        columns={columns}
        innerRef={tablaRef}
        nameURL={endPoint}
        scrollX = '30vw'
        extraParams={{ buscar: buscarValue || ''}}
      />
    </SimpleTableLayout>
  )
}

export default Formatos;