import { React, useEffect, useState, useCallback } from "react";
import { DefaultLayout } from "../../components/layouts";
import { useModel, useModels, useQuery } from "../../hooks"
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd'
import {
  BookOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import StatusResponse from "../../services/statusResponse";
import { ViewLoading } from "../../components";
import ImageCrop from "../../components/ImageCrop";

export const PublicacionDetalle = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const q = useQuery()
  const idPublicacion = q.get("id")
  const editing = !!idPublicacion
  const [saveLoading, setSaveLoading] = useState(false)
  const [fileList, setFileList] = useState([]);
  const breadcrumb=[
    { 
      name: "Publicaciones",
      to: "/publicaciones",
      icon: <BookOutlined  />
    },
    {
      name: editing ? "Editar" : "Agregar",
      to: editing ? `/publicaciones/detalle?id=${idPublicacion}` : `/publicaciones/nueva`, 
      icon: editing ? <EditOutlined /> : <PlusCircleOutlined /> }
  ];
  const {
    model,
    modelLoading,
  } = useModel({
    name: "publicacion",
    id: idPublicacion,
  })

  const [
    ciudades,
    ciudadesLoading
  ] = useModels({
    name: "ciudad",
    limite: 20,
  })

  const [
    secciones,
    seccionesLoading
  ] = useModels({
    name: "seccion",
    limite: 20,
  })

  const [
    responsables,
    responsablesLoading
  ] = useModels({
    name: "responsable",
    limite: 20,
  })

  const setFormData = useCallback((data) => {
    const { nombre, titulo, ano, revision, idSeccion, idCiudad, idResponsable } = data
    form.setFieldsValue({
      nombre,
      titulo,
      ano,
      revision,
      idSeccion,
      idCiudad,
      idResponsable,
    })
  },[form])
  
  useEffect(() => {
    let mounted = true
    if(mounted && editing && model){
      setFormData(model)
    }
    return () => mounted = false
  }, [editing, model, setFormData]);

  const onFinsih = async values => {
    const { nombre, descripcion, idCiudad } = values
    try{        
      setSaveLoading(true)
      
      let _body = {
        nombre,
        descripcion,
        idCiudad,
      }

      if(editing){
        _body.id = idPublicacion
      }

      const res = await StatusResponse.post("seccion/guardar", _body)
      if(res?.status === 400 && res?.errores !== null){
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}<br/></span>            
                )
              }
            </div>
          )
        })
      }else if(res?.status === 400 && res?.errores === null){
        Modal.error({
          title: res?.mensaje
        })
      }else if(res?.status === 200){
        let secondsToGo = 2
        const modal = Modal.success({
          title: res?.mensaje,
          cancelButtonProps: { style: { display: 'none' } },
          okButtonProps: { style: { display: 'none' } }
        })
        const timer = setInterval(() => {secondsToGo -= 1}, 1000)
        setTimeout(() => {
          clearInterval(timer)
          modal.destroy()
        }, secondsToGo * 1000)
        history.push("/secciones")
      }

    }catch(error){
      console.log(error)
    } finally{
      setSaveLoading(false)
    }
  };

  if(modelLoading) return <ViewLoading />
  
  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing ? `Editando Publicación: ${model?.nombre}` : "Nueva Publicación"}
      children={
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinsih}
        >
          <Row gutter={[16, 0]}>
            <Col sm={12} md={8}>
              <Form.Item 
                style={{ marginBottom: '0px' }}
                required
                name="nombre"
                label="Nombre"
                hasFeedback
                rules={[{ required: true, message: 'Por favor ingrese el nombre de la publicación' }]}
              >
                <Input 
                  maxLength={100}
                  placeholder="Escribe el nomnbre de la publicación"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item 
                style={{ marginBottom: '0px' }}
                required
                name="titulo"
                label="Título"
                hasFeedback
              >
                <Input 
                  maxLength={100}
                  placeholder="Escribe el título de la publicación"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: '0px' }}
                required
                name="idCiudad"
                label="Ciudad"
                hasFeedback
                rules={[{ required: true, message: 'Por favor seleccione la ciudad' }]}
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                  listItemHeight={10} 
                  listHeight={250}
                  loading={ciudadesLoading}
                  options={ciudades && ciudades.map(({id, nombre}) => ({value: id, label: nombre}))}
                  placeholder="Selecciona una ciudad"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: '0px' }}
                required
                name="idSeccion"
                label="Sección"
                hasFeedback
                rules={[{ required: true, message: 'Por favor seleccione la sección' }]}
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                  listItemHeight={10} 
                  listHeight={250}
                  loading={seccionesLoading}
                  options={secciones && secciones.map(({id, nombre}) => ({value: id, label: nombre}))}
                  placeholder="Selecciona una sección"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: '0px' }}
                required
                name="idResponsable"
                label="Responsable"
                hasFeedback
                rules={[{ required: true, message: 'Por favor seleccione al responsable' }]}
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                  listItemHeight={10} 
                  listHeight={250}
                  loading={responsablesLoading}
                  options={responsables && responsables.map(({id, nombre}) => ({value: id, label: nombre}))}
                  placeholder="Selecciona al responsable"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              label="Archivos"
              name="imagen"
              rules={[{ required: true, message: 'Requerido' }]} 
            >
              <ImageCrop
                fileList={ fileList } 
                onChange={(e) => setFileList(e) } 
              />
            </Form.Item>
          </Col>
          </Row>
          <Row gutter={[16, 0]}  style={{ marginTop: '10px' }}>
            <Col sm={24}>
              <Form.Item>
                <Button 
                  htmlType="submit"
                  loading={saveLoading}
                  type="primary"
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>  
        </Form>
      }
    />
  );
};