import React from 'react';
import {Button, Menu, Dropdown} from 'antd';
import {MoreOutlined} from '@ant-design/icons';

const ActionsButton = ({
                         options = [],
                         size = "",
                         text = "",
                         placement = "bottom",
                         type = "",
                         style = {}
                       }) => {
  return (
    <Dropdown
      overlay={
        <Menu>
          {options.map((item, i) => (
            <Menu.Item
              key={item?.key ? item.key : i}
              icon={item.icon}
              onClick={item.onClick}
              style={item.styleProps}
              hidden={item.hidden}
            >
              {item.name}
            </Menu.Item>
          ))}
        </Menu>
      }
      placement={placement}
    >
      <Button
        size={size}
        type={type}
        style={style}
      >
        <MoreOutlined/> {text}
      </Button>
    </Dropdown>
  );
};

export default ActionsButton;
