export const ambitos = [
  {value: "Estatal", label: "Estatal"},
  {value: "Informativo Portal", label: "Informativo Portal"},
  {value: "Municipal", label: "Municipal"},
  {value: "Sindicato", label: "Sindicato"},
]

export const dateFormat = 'DD/MM/YYYY'
export const dateUSFormat = 'YYYY-MM-DD'

export const leyes = [
  {value: "Estatal", label: "Estatal"},
  {value: "Federal", label: "Federal"},
]

export const localTimeZone = "America/Hermosillo"
export const NON_DIGIT = "/[^d]/g"

export const roles = [
  {value: "admin", label: "Administrador", key: "admin"},
  {value: "municipio", label: "Municipal", key: "municipio"},
  {value: "organismo-agua", label: "Organismo de Agua", key: "organismo-agua"},
  {value: "sindicato", label: "Sindicato", key: "sindicato"},
  {value: "transparencia", label: "Transparencia", key: "transparencia"},
]

export const paginas = [
  {key: "ADMON", value: "ADMON", label: 'Administración'},
  {key: "ComiteTransparencia", value: "ComiteTransparencia", label: 'Comité de Transparencia'},
  {key: "SIA", value: "SIA", label: 'Sistema Integral de Archivos'},
]

export const timeFormat12 = 'h:mm a'
export const timeFormat24 = 'HH:mm:ss'

export const meses = [
  {key: "Enero", value: "Enero", label: "Enero"},
  {key: "Febrero", value: "Febrero", label: "Febrero"},
  {key: "Marzo", value: "Marzo", label: "Marzo"},
  {key: "Abril", value: "Abril", label: "Abril"},
  {key: "Mayo", value: "Mayo", label: "Mayo"},
  {key: "Junio", value: "Junio", label: "Junio"},
  {key: "Julio", value: "Julio", label: "Julio"},
  {key: "Agosto", value: "Agosto", label: "Agosto"},
  {key: "Septiembre", value: "Septiembre", label: "Septiembre"},
  {key: "Octubre", value: "Octubre", label: "Octubre"},
  {key: "Noviembre", value: "Noviembre", label: "Noviembre"},
  {key: "Diciembre", value: "Diciembre", label: "Diciembre"},
]

export const trimestres = [
  {key: "1er Trimestre", value: "1er Trimestre", label: "1er Trimestre"},
  {key: "2do Trimestre", value: "2do Trimestre", label: "2do Trimestre"},
  {key: "3er Trimestre", value: "3er Trimestre", label: "3er Trimestre"},
  {key: "4to Trimestre", value: "4to Trimestre", label: "4to Trimestre"},
]

export const semestres = [
  {value: "1er Semestre", label: "1er Semestre"},
  {value: "2do Semestre", label: "2do Semestre"},
]

export const anual = () => {
  let anios = [];
  const anioActual = new Date();

  for (let i = 2006; i <= anioActual.getFullYear(); i++) {
    anios.push({key: i, value: i, label: i});
  }
  return anios.sort((a, b) => (a.key > b.key ? -1 : 1))
}

export const periodos = [
  {label: "NV", value: "NV", key: "NV"},
  {label: "Mensual", value: "mensual", key: "mensual"},
  {label: "Trimestral", value: "trimestral", key: "trimestral"},
  {label: "Semestral", value: "semestral", key: "semestral"},
  {label: "Anual", value: "anual", key: "anual"},
  {label: "36 Meses", value: "36meses", key: "36meses"},
]