import { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { DeleteOutlined, EditOutlined, PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { ActionsButton } from "../../../components";
import { SimpleTableLayout } from "../../../components/layouts";
import { Modal } from "antd";
import StatusResponse from "../../../services/statusResponse";
import TablaComponent from "../../../components/TablaComponent";

const Ponencias = () => {

  const endPoint = 'ponencia';
  let tablaRef = useRef(null);

  const [buscarParams, setBuscarParams] = useState({});

  const titulo = "Ponencias";
  const url = "/administracion/ponencias";
  const history = useHistory();

  const breadcrumbItems = [
    {
      name: titulo,
      to: "/administracion/ponencias",
    },
  ];

  const buttonData = {
    text: "Agregar Ponencia",
    to: () => history.push(url + "/nueva"),
    icon: <PlusOutlined />,
    props: { disabled: false, type: "primary" },
  };

  const columns = [
    {
      title: "",
      dataIndex: "Acciones",
      key: "Acciones",
      width: 120,
      align: "center",
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name: "Editar",
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/detalle?id=${item.idPonencia}`),
            },
            {
              name: "Eliminar",
              icon: <DeleteOutlined />,
              onClick: () => modalEliminar(item),
              styleProps: { color: "#ff4d4f" },
            },
          ]}
        />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (_, item) => (
        <Link to={`${url}/detalle?id=${item.idPonencia}`}>
          {`${item?.nombre}`}
        </Link>
      ),
    },
    {
      title: "Clave",
      dataIndex: "clave",
      key: "clave",
      render: (_, item) => (
        <Link to={`${url}/detalle?id=${item.idPonencia}`}>
          {`${item?.clave}`}
        </Link>
      ),
    },
  ];

  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar la ponencia "${valor?.nombre}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("ponencia/eliminar",{ idPonencia: valor.idPonencia});
        return new Promise((resolve, reject)=>{
          try {
            if (valor.idPonencia > 0) {
              if (res && res.status === 200) {
                tablaRef?.current?.refresh();
                resolve();
                resolve();
              } else if ( res?.status === 400 ) {
                Modal.error({
                  title: "Error: no se logro eliminar la ponencia",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  const onSearch = async (search) => {
    let params = {...buscarParams};
    if (search.length > 0) {
      params.buscar = search
    } else {
      delete params.buscar;
    }
    setBuscarParams(params)
  };

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder="Buscar..."
      onSearchClicked={onSearch}
      emptyText="Aún no hay registros."
    >
      <TablaComponent
        columns={columns}
        innerRef={tablaRef}
        nameURL={endPoint}
        expand=""
        order="idPonencia-desc"
        scroll={{ x: '30vw' }}
        extraParams={buscarParams}
      />
    </SimpleTableLayout>
  );
};

export default Ponencias;
