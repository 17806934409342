import React, { useMemo, useState } from "react";
import { Descriptions, Form, Modal } from "antd";
import {
  AccountBookOutlined,
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { DropdownOverlayButton } from "../../../components";
import SimpleTableLayout from "../../../components/layouts/SimpleTableLayout";
import { useModels } from "../../../hooks"
import { useHistory } from "react-router-dom"
import { Search } from "./form";
import StatusResponse from "../../../services/statusResponse";
import TablaComponent from "../../../components/TablaComponent";

const viewTitle = "Artículos";
const searchPlaceholder = "Buscar por título, descripción, etc.";
const emptyText = "No hay artículos registrados...";

export const ArticuloLista = () => {
  const [searchForm] = Form.useForm();
  const history = useHistory()
  const [searchLoading, setSearchLoading] = React.useState(false)
  const [search, setSearch] = useState('');
  const [idArticulo, setIdArticulo] = useState('');


  const [request, setRequest] = React.useState({})

  const breadcrumb=[
    { 
      name: "Artículos", 
      to: "/administracion/articulos",
      icon: <AccountBookOutlined /> 
    }
  ];

  const buttonData = {
    text: "Nueva Artículo",
    icon: <PlusCircleOutlined /> ,
    to: () => history.push("/administracion/articulos/nueva")
  }

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels(request);



  const sinDefinir = <i>-Sin definir-</i>

  const renderSimple = (prop) => prop || sinDefinir;

  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar el "${valor?.titulo}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("articulo",{ id: valor.id});
        return new Promise((resolve, reject)=>{
          try {
            if (valor.id > 0) {
              if (res && res.status === 200) {
                refreshModels();
                resolve();
              } else if ( res?.status === 400 ) {
                Modal.error({
                  title: "Error",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 100,
      render: renderSimple
    },
    {
      title: "Padre",
      width: 150,
      dataIndex: "padre",
      key: "padre",
      ellipsis: true,
      fixed: "left",
      render: (prop) => prop?.titulo || sinDefinir
    },
    {
      title: "Ámbito",
      width: 150,
      dataIndex: "ambito",
      key: "ambito",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Título",
      width: 150,
      dataIndex: "titulo",
      key: "titulo",
      ellipsis: true,
      render: renderSimple
    },
    {
      title: "Ley",
      width: 150,
      dataIndex: "ley",
      key: "ley",
      render: renderSimple
    },
    {
      title: "Descripción",
      width: 150,
      dataIndex: "descripcion",
      key: "descripcion",
      ellipsis: true,
      render: renderSimple
    },
    {
      title: "Periodo",
      width: 150,
      dataIndex: "tiempoValidacion",
      key: "tiempoValidacion",
      render: renderSimple
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, row) => (
        <DropdownOverlayButton
          options={[
            {
              name: "Ver detalle",
              icon: <EyeOutlined />,
              onClick: () => modalInfo(row)
            },
            {
              name: "Editar información",
              icon: <EditOutlined />,
              onClick: () => history.push(`articulos/detalle?id=${row?.id}`),
              styleProps: {
                color: "#4495bb"
              }
            },
            {
              name: "Eliminar",
              icon: <LockOutlined />,
              onClick: () => modalEliminar(row),
              styleProps: {
                color: "#f5222d"
              }
            }
          ]}
        />
      ),
    },
  ];

  const ContentModal = ({row}) => {
    return (
      <div>
        <Descriptions
          bordered
          column={1}
          size="small"
          title="Información del artículo"
        >
          <Descriptions.Item labelStyle={{"font-weight": "bold"}} label="Título">{row?.titulo || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"font-weight": "bold"}} label="Ámbito">{row?.ambito || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"font-weight": "bold"}} label="Art. Padre">{row?.padre?.titulo || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"font-weight": "bold"}} label="Ley">{row?.ley || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"font-weight": "bold"}} label="Descripción">{row?.descripcion || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"font-weight": "bold"}} label="Periodo">{row?.tiempoValidacion || sinDefinir}</Descriptions.Item>

        </Descriptions>
      </div>
    )
  }

  const modalInfo = (v) => {
    
    Modal.confirm({
      title: "",
      icon: null,
      content: <ContentModal row={v}/>,
      okText: "Ok",
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { primary: true },
      closable: true,
      width:1000
    });
  };

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    const { idArticulo, buscar } = searchForm.getFieldsValue(true);

    if (idArticulo) {
      setIdArticulo(idArticulo);
    }
    console.log(search)
    if (buscar) {
      setSearch(buscar);
    }
    
    setSearchLoading(false)
  },[searchForm, search]);

  // const onChangeCallback = React.useCallback(
  //   async ( page, size ) => {
  //     const { idArticulo, buscar } = searchForm.getFieldsValue(true);
  //     // let params = {};
  //     // if (buscar?.length > 0)
  //     //   params.q = buscar;
  //     // if (idArticulo)
  //     //   params.idArticuloPadre = idArticulo;
  //     // setPageSize(size);
  //     // await refreshModels(true, { 
  //     //   pagina: page,
  //     //   limite: size,
  //     //   ...params
  //     // })
  // }, [searchForm]);

  
  const extraParams = useMemo(() => {
    return {
      idArticuloPadre: idArticulo,
      q: search
    }
  }, [idArticulo, search]);

  

  return (
    <SimpleTableLayout
      buttonData={buttonData}
      breadcrumbItems={breadcrumb}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearch}
      emptyText={emptyText}
      withSearchButton={false}
    >
      <Search
        disabledButtons={modelsLoading}
        form={searchForm}
        loading={searchLoading}
        onClean={() => searchForm.resetFields()}
        onSearch={onSearch}
      />
      <TablaComponent 
        nameURL={'articulo'}
        columns={columns}
        order={'id-desc'}
        expand='padre'
        extraParams={extraParams}
      />
    </SimpleTableLayout>
  );
};