import { React, useEffect, useState, useCallback } from "react";
import { DefaultLayout } from "../../../components/layouts";
import { useModel, useModels, useQuery } from "../../../hooks"
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd'
import {
  AccountBookOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import StatusResponse from "../../../services/statusResponse";
import { ViewLoading } from "../../../components";
import { ambitos, leyes, periodos } from "../../../constants";

export const ArticuloDetalle = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const q = useQuery()
  const id = q.get("id")
  const editing = !!id
  const [saveLoading, setSaveLoading] = useState(false)
  const breadcrumb = [
    {
      name: "Artículo",
      to: "/administracion/articulos",
      icon: <AccountBookOutlined />
    },
    {
      name: editing ? "Editar" : "Agregar",
      to: editing ? `/administracion/articulos/detalle?id=${id}` : `/administracion/articulos/nueva`,
      icon: editing ? <EditOutlined /> : <PlusCircleOutlined />
    }
  ];

  const [
    articulos,
    articulosLoading
  ] = useModels({
    name: 'articulo?padres=1',
    ordenar: 'id-asc',
    limite: -1,
  })

  const {
    model,
    modelLoading,
  } = useModel({
    name: "articulo",
    id: id,
  })

  const setFormData = useCallback((data) => {
    form.setFieldsValue({
      titulo: data?.titulo,
      descripcion: data?.descripcion,
      articuloPadre: data?.idArticuloPadre,
      ambito: data?.ambito,
      ley: data?.ley,
      tiempoValidacion: data?.tiempoValidacion
    })
  }, [form])

  useEffect(() => {
    if (editing && model) {
      setFormData(model)
    }
  }, [editing, model, setFormData]);

  const onFinsih = async values => {
    const { titulo, descripcion, articuloPadre, ambito, ley, tiempoValidacion } = values
    try {
      setSaveLoading(true)

      let _body = {
        titulo,
        descripcion,
        ambito,
        ley,
        tiempoValidacion
      }

      if (articuloPadre) {
        _body.idArticuloPadre = articuloPadre
      }

      if (editing) {
        _body.id = id
      }

      const res = await StatusResponse.post("articulo", _body)
      if (res?.status === 400 && res?.errores !== null) {
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}<br /></span>
                )
              }
            </div>
          )
        })
      } else if (res?.status === 400 && res?.errores === null) {
        Modal.error({
          title: res?.mensaje
        })
      } else if (res?.status === 200) {
        let secondsToGo = 2
        const modal = Modal.success({
          title: res?.mensaje,
          cancelButtonProps: { style: { display: 'none' } },
          okButtonProps: { style: { display: 'none' } }
        })
        const timer = setInterval(() => { secondsToGo -= 1 }, 1000)
        setTimeout(() => {
          clearInterval(timer)
          modal.destroy()
        }, secondsToGo * 1000)
        history.push("/administracion/articulos")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  if (modelLoading) return <ViewLoading />

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing ? `Editando Artículo: ${model?.titulo}` : "Nuevo Artículo"}
      children={
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinsih}
          initialValues={
            !editing ? {
              tiempoValidacion: "NV"
            } : {}
          }
        >
          <Row gutter={[16, 0]}>
            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: '0px' }}
                required
                name="titulo"
                label="Título"
                hasFeedback
                rules={[{ required: true, message: 'Por favor ingrese el título del artículo' }]}
              >
                <Input
                  maxLength={255}
                  placeholder="Escribe el título del artículo"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: '0px' }}
                required
                name="articuloPadre"
                label="Artículo Padre"
                hasFeedback
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  loading={articulosLoading}
                  options={articulos ? articulos.map((m, i) => ({ label: `${m.ambito} - ${m.titulo}`, value: m.id, key: i })) : []}
                  placeholder="Selecciona un artículo padre"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: '0px' }}
                required
                name="ambito"
                label="Ámbito"
                hasFeedback
                rules={[{ required: true, message: 'Por favor seleccione el ámbito' }]}
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  options={ambitos}
                  rules={[{ required: true, message: 'Por favor seleccione un ámbito' }]}
                  placeholder="Selecciona un ámbito"
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={24}>
              <Form.Item
                style={{ marginBottom: '0px' }}
                required
                name="descripcion"
                label="Descripción"
                hasFeedback
              >
                <Input.TextArea
                  placeholder="Escribe la descripción del artículo"
                  rows={4}
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: '0px' }}
                required
                name="ley"
                label="Ley"
                hasFeedback
                rules={[{ required: true, message: 'Por favor seleccione el tipo de ley' }]}
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  options={leyes}
                  placeholder="Selecciona un tipo de ley"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: '0px' }}
                required
                name="tiempoValidacion"
                label="Período"
                hasFeedback
                rules={[{ required: true, message: 'Por favor seleccione el período' }]}
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  options={periodos}
                  placeholder="Selecciona un tipo de período"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]} style={{ marginTop: '10px' }}>
            <Col sm={24}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={saveLoading}
                  type="primary"
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      }
    />
  );
};