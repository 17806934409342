import React from "react";
import { Button, Menu, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";

const DropdownOverlayButton = ({ options = [] }) => {
  return (
    <Dropdown
      overlay={
        <Menu>
          {options.map((item, i) => (
            <Menu.Item
              key={i}
              icon={item.icon}
              onClick={item.onClick}
              style={item.styleProps}
              disabled={item.disabled}
            >
              {item.name}
            </Menu.Item>
          ))}
        </Menu>
      }
      placement="bottom"
    >
      <Button>
        Acción <MoreOutlined />
      </Button>
    </Dropdown>
  );
};

export default DropdownOverlayButton;
