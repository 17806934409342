import React, {useState, useEffect, useMemo} from "react";
import {Form, Row, Col, Input, Button, Select,} from "antd";
import {SaveOutlined, FileTextOutlined, EditOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {respuestas} from "../../../utilities";
import {useModel, useQuery} from "../../../hooks";
import {DefaultLayout} from "../../../components/layouts";
import HttpService from "../../../services/httpService";

const reglas = {
  input: [{required: true, message: "No dejar vacío",}],
  select: [{required: true, message: "Selecciona una opción",}],
};

const VariableDetalle = () => {

  const endPoint = "acta-variable";
  const [form] = Form.useForm();
  const history = useHistory();
  const query = useQuery();
  const nombreVariable = query.get("nombre");
  const editando = Boolean(nombreVariable);

  const [request, setRequest] = useState({});
  const [selectValue, setSelectValue] = useState('');

  const breadcrumbItems = [
    {
      name: "Variables",
      to: "/administracion/actas/variables",
      icon: <FileTextOutlined/>
    },
    {
      name: editando ? "Editar" : "Agregar",
      to: editando ? `/administracion/actas/variables/detalle?nombre=${nombreVariable}` : `/administracion/actas/variables/nueva`,
      icon: editando ? <EditOutlined/> : <PlusCircleOutlined/>
    }
  ];

  const requestParams = useMemo(
    () => ({
      name: endPoint,
      id: nombreVariable,
    }), [nombreVariable]);

  const {model} = useModel(request);

  const onFinish = async (values) => {

    try {

      let body = {
        ...values,
        nombre: nombreVariable || values?.nombre,
      };

      const resp = await HttpService.post(endPoint, body);

      respuestas(resp);

      if (resp?.mensaje !== 'Variable guardada.') {
        console.log('resp', resp);
      }

    } catch (error) {
      console.log("Error al guardar: ", error);
    } finally {
      history.push('/administracion/actas/variables');
    }

  };

  // Setear Formulario
  useEffect(() => {

    if (editando && model) {
      form.setFieldsValue({
        ...model,
      });
      setSelectValue(model?.tipo)
    } else {
      form.setFieldsValue({tipo: 'estatica'})
    }

  }, [editando, form, model]);

  // Request models
  useEffect(() => {
    setRequest(requestParams);
    return () => setRequest({});
  }, [requestParams]);


  return (
    <DefaultLayout
      breadcrumbItems={breadcrumbItems}
    >
      <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
        <Row gutter={10}>
          <Col span={8}>
            <Form.Item

              name="nombre"
              label="Nombre"
              hasFeedback
              rules={reglas.input}
            >
              <Input
                placeholder="NOMBRE_VARIABLE"
                //disabled={editando}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="tipo"
              label="Tipo"
              hasFeedback
              rules={reglas.input}
            >
              <Select
                style={{width: '100%'}}
                allowClear={true}
                showSearch
                placeholder="Seleccione un tipo"
                onChange={setSelectValue}
                options={[
                  {label: 'Estática', value: 'estatica'},
                  {label: 'Dinámica', value: 'dinamica'},
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="valor"
              label="Valor"
              dependencies={['tipo']}
              rules={[
                {
                  required: selectValue === 'estatica' ? true : false,
                  message: 'Ingresa un valor',
                },
              ]}
              hasFeedback
            >
              <Input
                placeholder="Escriba un valor..."
                disabled={selectValue === 'dinamica'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={16}>
            <Form.Item
              name="descripcion"
              label="Descripción"
              hasFeedback
            >
              <Input.TextArea placeholder="Escriba una descripción de la variable" rows={4}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={8}>
            <Form.Item>
              <Button
                icon={<SaveOutlined/>}
                type="primary"
                block
                htmlType="submit"
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DefaultLayout>
  );
};

export default VariableDetalle;
