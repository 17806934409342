import {Listado} from "./Listado";
import {Detalle} from "./Detalle";
import {Link} from "react-router-dom";
import {defaultText} from "../../../utilities";

const titulo = "Tipos de Publicaciones";
const endPoint = 'publicacion-tipo'
const url = '/administracion/publicaciones/tipos'
const expand = 'mediaPublicacionTipo, media'
const orden = 'id'
const idModelo = 'id'
const media = 'mediaPublicacionTipo'


const columnas = [
  {
    title: 'Titulo',
    index: 'titulo',
    key: 'titulo',
    dataIndex: 'titulo',
    render: (item, row) => defaultText(item, row, url, idModelo)
  },
  {
    title: 'Descripción',
    index: 'descripcion',
    key: 'descripcion',
    dataIndex: 'descripcion',
    render: (item, row) => defaultText(item, row, url, idModelo)
  }]

const PublicacionTipo = () => (<Listado
  viewTitle={titulo}
  endPoint={endPoint}
  expand={expand}
  url={url}
  orden={orden}
  columnas={columnas}
  idModelo={idModelo}
/>)

const PublicacionTipoDetalle = () => (<Detalle
  viewTitle={titulo}
  endPoint={endPoint}
  expand={expand}
  url={url}
  orden={orden}
  idModelo={idModelo}
  media={media}
/>)

export {PublicacionTipo, PublicacionTipoDetalle}
