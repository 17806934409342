import {React} from "react";
import {useAuth} from "../hooks";
import {message, Modal, notification} from "antd";

const Respuesta = (res) => {
  // const {user} = useAuth();
  let exito = true;

  if (!res) {
    return false;
  }

  if (res?.status >= 400 && res?.status < 499) {
    const newArray = Object.values(res?.errores)
    notification.error({
      message: res?.mensaje,
      description: (
        <div>
          {
            newArray.map((m, i) =>
              <span key={(i + 1)}>- {m}<br/></span>
            )
          }
        </div>
      ),
      placement: 'bottomRight'
    })
    exito = false;
  } else if ((res?.status >= 500) && res?.errores === null) {
    notification.error({
      message: "Atención",
      description: res?.mensaje,
      placement: 'bottomRight'
    })
    exito = false;
  } else if (res?.status === 200) {
    notification.success({
      message: '¡Éxito!',
      description: res?.mensaje,
      placement: 'bottomRight',
      duration: .5
    })
    exito = true;
  }
  return exito;
};

/**
 * @Respuestas
 * Regresa el estatus en un mensaje al usuario
 * @res = mensaje
 * @text = texto extra
 * @time = tiempo de duración de la notificación
 */
const Respuestas = (res, text = "", mostrar = true) => {
  let _success = false;
  if (!res) return "Error en respuesta";

  const eliminaDuplicados = (arr) => {
    return arr.filter((valor, indice) => {
      return arr.indexOf(valor) === indice;
    });
  }

  if (res?.status >= 400 && res?.status < 499) {
    _success = false;
    if (res?.errores !== null) {
      let errores = eliminaDuplicados(Object.values(res?.errores))
      notification.error({
        message: 'Atención',
        description: errores.map((e, i) => <React.Fragment key={`${i}-error`}><span>- {e}</span><br/></React.Fragment>),
        placement: 'bottomRight',
        time: 1
      });
    } else {
      notification.error({
        message: 'Atención',
        description: res?.mensaje,
        placement: 'bottomRight',
        time: 1
      });
    }
  } else if ((res?.status >= 500) && res?.errores === null) {
    _success = false;

    notification.error({
      message: 'Atención',
      description: res?.mensaje === null ? 'Hubo un problema del lado del servidor.' : res?.mensaje,
      placement: 'bottomRight',
      time: 1
    });
  } else if (res?.status >= 200 && res?.status < 399) {
    _success = true;
    if (mostrar) {
      notification.success({
        message: '¡Éxito!',
        description: text === "" ? `${res?.mensaje}` : `${res?.mensaje}, ${text}`,
        placement: 'bottomRight',
        time: 1
      });
    }
  } else if (res === "error") {
    _success = false;
    if (mostrar) {
      notification.error({
        message: 'Atención',
        description: text,
        placement: 'bottomRight',
        time: 1
      });
    }

  } else if (res === "exito") {
    _success = true;
    if (mostrar) {
      notification.success({
        message: '¡Éxito!',
        description: text,
        placement: 'bottomRight',
        time: 1
      });
    }
  }

  if (res.file) {
    /*if (res.file.status !== 'uploading') {
      console.log(res.file, res.fileList);
    }*/
    if (res.file.status === 'done') {
      notification.success({
        message: '¡Éxito!',
        description: `${res.file.name} el archivo se subio correctamente`,
        placement: 'bottomRight',
        time: 1
      });
    } else if (res.file.status === 'error') {
      console.log(res.file);
      notification.error({
        message: 'Atención',
        description: res?.file?.response?.mensaje || `Fallo en la subida del archivo.`,
        placement: 'bottomRight',
        time: 1
      });
    }
  }

  return _success;
};

export {Respuesta, Respuestas};