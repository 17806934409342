import { React, useEffect, useState, useCallback } from "react";
import { DefaultLayout } from "../../../components/layouts";
import { useModel, useQuery } from "../../../hooks"
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd'
import {
  EditOutlined,
  GoldOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import StatusResponse from "../../../services/statusResponse";
import { ViewLoading } from "../../../components";
import { ambitos } from "../../../constants";

export const EnteDetalle = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const q = useQuery()
  const id = q.get("id")
  const editing = !!id
  const [saveLoading, setSaveLoading] = useState(false)
  const breadcrumb=[
    { 
      name: "Entes",
      to: "/administracion/entes",
      icon: <GoldOutlined />
    },
    {
      name: editing ? "Editar" : "Agregar",
      to: editing ? `/administracion/entes/detalle?id=${id}` : `/administracion/entes/nueva`,
      icon: editing ? <EditOutlined /> : <PlusCircleOutlined />
    }
  ];
  const {
    model,
    modelLoading,
    // eslint-disable-next-line no-unused-vars
    updateModel, updateModelLoading,
  } = useModel({
    name: "ente",
    id: id,
  })

  const setFormData = useCallback((data) => {
    const { titulo, descripcion, ambito } = data
    form.setFieldsValue({
      titulo,
      descripcion,
      ambito
    })
  },[form])
  
  useEffect(() => {
    let mounted = true
    if(mounted && editing && model){
      setFormData(model)
    }
    return () => mounted = false
  }, [editing, model, setFormData]);

  const onFinsih = async values => {
    const { titulo, descripcion, ambito } = values
    try{
      setSaveLoading(true)   
      let _body = {
        titulo,
        descripcion,
        ambito,
      }

      if(editing){
        _body.id = parseInt(id)
      }

      // ! useHttp no filtro correctamente los errores del back, no se recomienda usar
      // ! no usar updateModel(_body)

      const res = await StatusResponse.post("ente", _body)
      if(res?.status === 400 && res?.errores !== null){
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}<br/></span>
                )
              }
            </div>
          )
        })
      }else if(res?.status === 400 && res?.errores === null){
        Modal.error({
          title: res?.mensaje
        })
      }else if(res?.status === 200){
        let secondsToGo = 2
        const modal = Modal.success({
          title: res?.mensaje,
          cancelButtonProps: { style: { display: 'none' } },
          okButtonProps: { style: { display: 'none' } }
        })
        const timer = setInterval(() => {secondsToGo -= 1}, 1000)
        setTimeout(() => {
          clearInterval(timer)
          modal.destroy()
        }, secondsToGo * 1000)
        history.push("/administracion/entes")
      }

    }catch(error){
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  if(modelLoading) return <ViewLoading />
  
  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing ? `Editando Ente: ${model?.titulo}` : "Nuevo Ente"}
      children={
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinsih}
        >
          <Row gutter={[16, 0]}>
            <Col sm={12} md={8}>
              <Form.Item 
                style={{ marginBottom: '0px' }}
                required
                name="titulo"
                label="Nombre"
                hasFeedback
                rules={[{ required: true, message: 'Por favor ingrese el nombre de la ciudad' }]}
              >
                <Input 
                  maxLength={100}
                  placeholder="Escribe el nombre de la Institución"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item 
                style={{ marginBottom: '0px' }}
                required
                name="descripcion"
                label="Descripción"
                hasFeedback
              >
                <Input 
                  maxLength={100}
                  placeholder="Escribe la dirección de la Institución"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: '0px' }}
                required
                name="ambito"
                label="Ámbito"
                hasFeedback
                rules={[{ required: true, message: 'Por favor seleccione el ámbito' }]}
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                  listItemHeight={10} 
                  listHeight={250}
                  options={ambitos}
                  rules={[{ required: true, message: 'Por favor seleccione un ámbito' }]}
                  placeholder="Selecciona un ámbito"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}  style={{ marginTop: '10px' }}>
            <Col sm={24}>
              <Form.Item>
                <Button 
                  htmlType="submit"
                  loading={saveLoading}
                  type="primary"
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>  
        </Form>
      }
    />
  );
};