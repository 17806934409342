import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ViewLoading from '../../components/ViewLoading';
import DefaultLayout from '../../components/layouts/DefaultLayout';
import { roles } from '../../constants';
import { useAuth } from "../../hooks";
import StatusResponse from '../../services/statusResponse';

const { Title, Text } = Typography;

const ProfileView = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  console.log(user)

  const breadcrumbItems = [
    {
      name: "Perfil",
      to: "/perfil",
      icon: <UserOutlined />,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push("/"),
    icon: <ArrowLeftOutlined />,
    props: { disabled: false, type: "primary" },
  };

  useEffect(() => {
    if (!user) return;
    form.setFieldsValue({
      nombre: user.nombre,
      correo: user.correo,
      rol: user.rol,
    });

  }, [user, form]);

  if (loading || !user) return <ViewLoading />;

  const onFinish = async (values) => {
    setSaving(true);
    const { clave, confirmarClave } = values;
    if (confirmarClave !== clave) {
      Modal.warning({
        title: "Error",
        content: "Las contraseñas no coinciden, intente de nuevo.",
      });
      return;
    }

    let body = {
      nombre: values.nombre,
      correo: values.correo,
      rol: values.rol,
    };

    try {
      setLoading(true);
      body.id = user.id;

      if (clave && confirmarClave) {
        const cambiarClave = await StatusResponse.post(
          "usuario/cambiar-clave",
          {
            idUsuario: user?.id,
            clave: clave,
          }
        );

        if (cambiarClave.status !== 200) {
          message.error("No se pudo cambiar la contraseña");
          return;
        }
        delete body.clave; // ? Esto evita guardados múltiples al editar
      }

      const res = await StatusResponse.post("usuario/perfil", body);
      if (res) {
        if (res?.status === 400 && res?.errores !== null) {
          const newArray = Object.values(res?.errores);
          Modal.error({
            title: res?.mensaje,
            content: (
              <div>
                {newArray.map((m, i) => (
                  <span key={i + 1}>
                    {" "}
                    -{m} <br />
                  </span>
                ))}
              </div>
            ),
          });
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: { marginTop: "20vh" },
          });
        } else if (res?.status === 200) {
          message.success({
            content: res?.mensaje,
            style: { marginTop: "20vh" },
          });
          history.push("/");
        }
      }
    } catch (e) {
      console.log("Error al guardar: ", e);
    } finally {
      setLoading(false);
      setSaving(false);
    }
  };

  return (
    <DefaultLayout
      title="Perfil"
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      {/* <Row style={{ marginTop: 20 }}>
        <Col span={8}>
          <Typography.Title level={2}>Perfil</Typography.Title>
        </Col>
      </Row> */}
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
      >
        <Row style={{ marginTop: 20 }} gutter={16}>
          <Col sm={24} md={6}>
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "Favor de ingresar su Nombre.",
                  type: "string"
                },
              ]}
            >
              <Input type="text" autocomplete="one-time-code" />
            </Form.Item>
          </Col>
          <Col sm={24} md={6}>
            <Form.Item
              label="Correo electrónico"
              name="correo"
              rules={[
                {
                  required: true,
                  message: "Favor de ingresar su Correo Electrónico.",
                  type: "email"
                },
              ]}
            >
              <Input type="email" autocomplete="one-time-code" />
            </Form.Item>
          </Col>
          <Col sm={24} md={6}>
            <Form.Item label="Rol" name='rol'>
              <Select
                options={roles}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Title level={5}>
              {" "}
              Cambiar Contraseña{" "}
              <Text type="secondary">
                {" "}
                - (Llene los siguientes campos sólo en caso de cambiar la
                contraseña) -{" "}
              </Text>
            </Title>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xxl={8}>
            <Form.Item
              name="clave"
              label="Contraseña"
              hasFeedback
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xxl={8}>
            <Form.Item
              name="confirmarClave"
              label="Confirmar Contraseña"
              hasFeedback
              // que coincida con el campo clave
              dependencies={["clave"]}
              rules={[
                {
                  // que sea requerido cuando hay algo en el campo clave
                  required: form.getFieldValue("clave"),
                  message: "Favor de confirmar su Contraseña.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("clave") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Las contraseñas no coinciden, intente de nuevo."
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item>
              <Button
                style={{ float: "right" }}
                type="primary"
                htmlType="submit"
                loading={saving}
              >
                GUARDAR
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DefaultLayout>
  )
}

export default ProfileView;