import { React, useEffect, useState, useCallback } from "react";
import { DefaultLayout } from "../../../components/layouts";
import { useModel, useModels, useQuery } from "../../../hooks"
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd'
import {
  EditOutlined,
  PlusCircleOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import StatusResponse from "../../../services/statusResponse";
import { ViewLoading } from "../../../components";

export const CategoriaDetalle = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const q = useQuery()
  const id = q.get("id")
  const editing = !!id
  const [saveLoading, setSaveLoading] = useState(false)
  const breadcrumb=[
    { 
      name: "Categorías",
      to: "/administracion/categorias",
      icon: <ReconciliationOutlined />
    },
    {
      name: editing ? "Editar" : "Agregar",
      to: editing ? `/administracion/categorias/detalle?id=${id}` : `/administracion/categorias/nueva`,
      icon: editing ? <EditOutlined /> : <PlusCircleOutlined /> }
  ];
  const {
    model,
    modelLoading,
  } = useModel({
    name: "documento-categoria",
    id: id,
  })

  const [
    entes,
    entesLoading,
  ] = useModels({
    name: "ente",
    ordenar: 'titulo-asc',
    limite: -1
  })

  const setFormData = useCallback((data) => {
    const { titulo, descripcion, idEnte } = data
    form.setFieldsValue({
      titulo,
      descripcion,
      idEnte
    })
  },[form])
  
  useEffect(() => {
    let mounted = true
    if(mounted && editing && model){
      setFormData(model)
    }
    return () => mounted = false
  }, [editing, model, setFormData]);

  const onFinsih = async values => {

    let _body = {
      ...values
    }

    if(editing){
      _body.id = id
    }

    try {
      setSaveLoading(true)
      const res2 = await StatusResponse.post("documento-categoria", _body)
      if(res2?.status === 400 && res2?.errores !== null){
        const newArray = Object.values(res2?.errores)
        Modal.error({
          title: res2?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}<br/></span>            
                )
              }
            </div>
          )
        })
      }else if(res2?.status === 400 && res2?.errores === null){
        Modal.error({
          title: res2?.mensaje
        })
      }else if(res2?.status === 200){
        let secondsToGo = 2
        const modal = Modal.success({
          title: res2?.mensaje,
          cancelButtonProps: { style: { display: 'none' } },
          okButtonProps: { style: { display: 'none' } }
        })
        const timer = setInterval(() => {secondsToGo -= 1}, 1000)
        setTimeout(() => {
          clearInterval(timer)
          modal.destroy()
        }, secondsToGo * 1000)
        history.push("/administracion/categorias")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  if(modelLoading) return <ViewLoading />
  
  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing ? `Editando Categoría: ${model?.titulo}` : "Nueva Categoría"}
      children={
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinsih}
        >
          <Row gutter={[16, 0]}>
            <Col sm={12} md={8}>
              <Form.Item 
                style={{ marginBottom: '0px' }}
                required
                name="titulo"
                label="Título"
                hasFeedback
                rules={[{ required: true, message: 'Por favor ingrese el nombre de la categoría' }]}
              >
                <Input 
                  maxLength={100}
                  placeholder="Escribe el nombre de la categoría"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: '0px' }}
                required
                name="descripcion"
                label="Descripción"
                hasFeedback
              >
                <Input
                  maxLength={100}
                  placeholder="Escribe la dirección de la categoría"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: '0px' }}
                required
                name="idEnte"
                label="Ente"
                hasFeedback
                rules={[{ required: true, message: 'Por favor seleccione un ente' }]}
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                  listItemHeight={10} 
                  listHeight={250}
                  options={entes ? entes.map((m, i) => ({ label: `${m.titulo}`, value: m.id, key: i, item: m })) : []}
                  rules={[{ required: true, message: 'Por favor seleccione un ente' }]}
                  placeholder="Selecciona un ente"
                  loading={entesLoading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}  style={{ marginTop: '10px' }}>
            <Col sm={24}>
              <Form.Item>
                <Button 
                  htmlType="submit"
                  loading={saveLoading}
                  type="primary"
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>  
        </Form>
      }
    />
  );
};