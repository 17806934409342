import React, { useMemo } from "react";
import { Descriptions, Modal } from "antd";
import { GoldOutlined, EyeOutlined, PlusCircleOutlined, EditOutlined } from "@ant-design/icons";
import { DropdownOverlayButton } from "../../../components";
import SimpleTableLayout from "../../../components/layouts/SimpleTableLayout";
import { useHistory } from "react-router-dom";
import TablaComponent from "../../../components/TablaComponent";

const viewTitle = "Entes";
const searchPlaceholder = "Buscar por nombre, etc.";
const emptyText = "No hay entes registrados...";

const breadcrumbItems = [
  {
    name: "Entes",
    to: "/administracion/entes",
    icon: <GoldOutlined />,
  },
];

export const EnteLista = () => {
  const history = useHistory();
  let tablaRef = React.useRef(null);
  const [search, setSearch] = React.useState("");
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [row, setRow] = React.useState(null);
  const [modalVisible, setModalVisible] = React.useState(false);

  const buttonData = {
    text: "Agregar",
    icon: <PlusCircleOutlined />,
    to: () => history.push("/administracion/entes/nueva"),
  };


  const sinDefinir = <i>-Sin definir-</i>

  const renderSimple = (prop) => prop || sinDefinir;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 100,
      render: renderSimple,
    },
    {
      title: "Título",
      dataIndex: "titulo",
      key: "titulo",
      fixed: "left",
      ellipsis: true,
      render: renderSimple
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      fixed: "left",
      ellipsis: true,
      render: renderSimple
    },
    {
      title: "Ámbito",
      dataIndex: "ambito",
      key: "ambito",
      fixed: "left",
      render: renderSimple,
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, row) => (
        <DropdownOverlayButton
          options={[
            {
              name: "Ver detalle",
              icon: <EyeOutlined />,
              onClick: () => modalInfo(row),
            },
            {
              name: "Editar",
              icon: <EditOutlined />,
              onClick: () =>
                history.push(`/administracion/entes/detalle?id=${row?.id}`),
            },
          ]}
        />
      ),
    },
  ];

  const ContentModal = ({ row }) => {
    return (
      <div>
        <Descriptions
          bordered
          column={1}
          size="small"
          title="Información del ente"
        >
          <Descriptions.Item label="ID" labelStyle={{ fontWeight: "bold" }}>
            {" "}
            {row?.id || sinDefinir}{" "}
          </Descriptions.Item>
          <Descriptions.Item label="Nombre" labelStyle={{ fontWeight: "bold" }}>
            {" "}
            {row?.titulo || sinDefinir}{" "}
          </Descriptions.Item>
          <Descriptions.Item
            label="Descripción"
            labelStyle={{ fontWeight: "bold" }}
          >
            {" "}
            {row?.descripcion || sinDefinir}{" "}
          </Descriptions.Item>
          <Descriptions.Item label="Ámbito" labelStyle={{ fontWeight: "bold" }}>
            {" "}
            {row?.ambito || sinDefinir}{" "}
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    setSearch(v);
    setTimeout(() => setSearchLoading(false), 1500);
  },[]);

  

  

  const extraParams = useMemo(() => {
    return {q: search}
  }, [search]);

  const modalInfo = async (v) => {
    setRow(v);
    setModalVisible(true);
  };

  // const onSearch = React.useCallback(
  //   async (v) => {
  //     setSearchLoading(true);
  //     setSearch(v);
  //     let params = {};
  //     if (v.length > 0) params = { ...params, q: v };
  //     params = { ...params, pagina: 0 };
  //     setTimeout(() => setSearchLoading(false), 1500);
  //   },
  //   []
  // );

  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearch}
      emptyText={emptyText}
    >
      <TablaComponent
        nameURL={'ente'}
        columns={columns}
        order={'id-desc'}
        extraParams={extraParams}
        innerRef={tablaRef}
      />
      <Modal
        onCancel={() => setModalVisible(false)}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ type: "primary" }}
        cancelText="Cerrar"
        visible={modalVisible}
        width={"50%"}
      >
        <ContentModal row={row} />
      </Modal>
    </SimpleTableLayout>
  );
};
