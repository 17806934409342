import {AuditOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Select as NormalSelect,
  Row,
  message,
  notification,
  Checkbox,
  Tooltip
} from "antd";
import locale from "antd/lib/date-picker/locale/es_ES";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import React, {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {Select, Uploader, ViewLoading} from "../../components";
import {DefaultLayout} from "../../components/layouts";
import {useAuth, useModel, useQuery} from "../../hooks";
import StatusResponse from "../../services/statusResponse";
import {Respuesta} from "../../utilities/Respuesta";

const styleRecurso = {
  backgroundColor: "#0070c1",
  color: "#fff",
};

const styleDenuncia = {
  backgroundColor: "#6f2fa0",
  color: "#fff",
};

const styleDefault = {
  backgroundColor: "lightgray",
  color: "#000",
};

const optionLitisRecurso = [
  {
    value: "I.- La clasificación de la información",
    label: "I.- La clasificación de la información",
  },
  {
    value: "II.- La declaración de inexistencia de información",
    label: "II.- La declaración de inexistencia de información",
  },
  {
    value: " III.- La declaración de incompetencia por el sujeto obligado",
    label: " III.- La declaración de incompetencia por el sujeto obligado",
  },
  {
    value: "IV.- La entrega de información incompleta.",
    label: "IV.- La entrega de información incompleta.",
  },
  {
    value:
      " V.- La entrega de información que no corresponda con lo solicitado.",
    label:
      " V.- La entrega de información que no corresponda con lo solicitado.",
  },
  {
    value:
      "VI.- La falta de respuesta a una solicitud de acceso a la información dentro de los plazos establecidos en esta Ley.",
    label:
      "VI.- La falta de respuesta a una solicitud de acceso a la información dentro de los plazos establecidos en esta Ley.",
  },
  {
    value:
      "VII.- La notificación, entrega o puesta a disposición de información en una modalidad o formato distinto al solicitado.",
    label:
      "VII.- La notificación, entrega o puesta a disposición de información en una modalidad o formato distinto al solicitado.",
  },
  {
    value:
      " VIII.- La entrega o puesta a disposición de información en un formato incomprensible y/o no accesible para el solicitante.",
    label:
      " VIII.- La entrega o puesta a disposición de información en un formato incomprensible y/o no accesible para el solicitante.",
  },
  {
    value: "IX.- Los costos o tiempos de entrega de la información.",
    label: "IX.- Los costos o tiempos de entrega de la información.",
  },
  {
    value: "X.- La falta de trámite a una solicitud.",
    label: "X.- La falta de trámite a una solicitud.",
  },
  {
    value: "XI.- La negativa a permitir la consulta directa de la información.",
    label: "XI.- La negativa a permitir la consulta directa de la información.",
  },
  {
    value:
      "XII.- La falta, deficiencia o insuficiencia de la fundamentación y/o motivación en la respuesta.",
    label:
      "XII.- La falta, deficiencia o insuficiencia de la fundamentación y/o motivación en la respuesta.",
  },
  {
    value: "XIII.- La orientación a un trámite específico.",
    label: "XIII.- La orientación a un trámite específico.",
  },
  {
    value:
      "XIV.- Otros actos u omisiones de los sujetos obligados derivados de la aplicación de la presente Ley.",
    label:
      "XIV.- Otros actos u omisiones de los sujetos obligados derivados de la aplicación de la presente Ley.",
  },
];

const optionsVistaOic = [
  {value: "SI", label: "SI"},
  {value: "NO", label: "NO"},
];

const DenunciaDetalle = () => {
  const history = useHistory();
  const q = useQuery();

  const idActa = q.get("idActa");
  const id = q.get("e");

  const {user} = useAuth();
  const ver = user?.permisoExtra?.includes(27)
  const rolAdmin = user?.rol === "admin";
  const editando = Boolean(id);

  const [tipoRecurso, setTipoRecurso] = React.useState("RECURSO");

  const [form] = Form.useForm();

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [sentido, setSentido] = React.useState({});
  const [respuestaInicial, setResInicial] = React.useState({});
  const [opcionRespuesta, setOpcionRespuesta] = React.useState({});
  const [informeVerificacion, setInformeVerificacion] = React.useState({});
  const [tipo, setTipo] = React.useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState("");
  const [respuestaInicialOtro, setRespuestaInicialOtro] = React.useState(null);
  const [respuestaOtro, setRespuestaOtro] = React.useState(null);
  const [respuestaInicialExtra, setRespuestaInicialExtra] = React.useState(null);
  const [respuestaExtra, setRespuestaExtra] = React.useState(null);

  const [listaArchivos, setListaArchivos] = useState([]);
  const [referencias, setReferencias] = useState([]);

  const extraParams = useMemo(() => ({
    idActa: idActa,
  }), [idActa]);

  const {model: modelCaso} = useModel({
    name: "acta-caso",
    id,
    extraParams: extraParams,
    expand: "acta, ponencia,media",
  });

  const {model, modelLoading} = useModel({
    name: "acta",
    id: idActa,
    expand: "ponencia",
  });

  const optionsInformeVerificacion = [
    {
      label: (
        <p style={{color: "black", fontWeight: "bold", margin: 0}}>
          Si cumple
        </p>
      ),
      options: [
        {
          value: 1,
          label:
            "Se encuentran actualizadas y publicadas las obligaciones denunciadas en la página de Internet por lo que respecta al periodo denunciado.",
        },
      ],
    },
    {
      label: (
        <p style={{color: "black", fontWeight: "bold", margin: 0}}>
          No cumple
        </p>
      ),
      options: [
        {
          value: 2,
          label:
            "No se encuentran actualizadas y publicadas las obligaciones denunciadas en la página de internet por lo que respecta al periodo denunciado.",
        },
      ],
    },
  ];

  const onChangeInforme = (value) => {
    const informe = {
      1: {
        informeValor: "SI cumple",
        informe:
          "Se encuentran actualizadas y publicadas las obligaciones denunciadas en la página de Internet por lo que respecta al periodo denunciado.",
      },
      2: {
        informeValor: "NO cumple",
        informe:
          "No se encuentran actualizadas y publicadas las obligaciones denunciadas en la página de internet por lo que respecta al periodo denunciado.",
      },
    };
    setInformeVerificacion(informe[value]);
  };

  const optionsSentido = [
    {
      value: 1,
      label:
        "Resulta IMPROCEDENTE La acción ejercitada por el ciudadano en contra del sujeto obligado, en virtud de que este instituto, se encuentra jurídicamente impedido para conocer sobre de la denuncia planteada por ser infundado el escrito de denuncia. ",
    },
    {
      value: 2,
      label:
        "Resulta IMPROCEDENTE La acción ejercitada por el ciudadano en contra del sujeto obligado, en virtud de que SI se encuentran publicadas las obligaciones de transparencia en el portal electrónico según lo establecido en la Ley de Transparencia",
    },
    {
      value: 3,
      label:
        "Resulta PROCEDENTE La acción ejercitada por el ciudadano en contra del sujeto obligado, en virtud de que no se encuentran publicadas las obligación de transparencia en el portal electrónico según lo establecido en la Ley de Transparencia",
    },
  ];

  const optionsSentidoRecurso = [
    {
      label: (
        <p style={{color: "black", fontWeight: "bold", margin: 0}}>
          SOBRESEER:
        </p>
      ),
      options: [
        {
          value: 4,
          label: "I.- El recurrente se desista",
        },
        {
          value: 5,
          label: "II.- El recurrente fallezca",
        },
        {
          value: 6,
          label:
            "III.- Ya que se quedó sin materia. Ello, toda vez que de las constancias que obran en autos, el sujeto obligado envió la información solicitada",
        },
        {
          value: 7,
          label:
            "IV.- Admitido el recurso de revisión, aparezca alguna causal de improcedencia",
        },
      ],
    },
    // {
    //   label: (
    //     <p style={{ color: "black", fontWeight: "bold", margin: 0 }}>
    //       DESECHAR:
    //     </p>
    //   ),
    //   options: [
    //     {
    //       value: 8,
    //       label:
    //         "I.- Sea extemporáneo por haber transcurrido el plazo establecido en el Artículo 138 de la presente Ley",
    //     },
    //     {
    //       value: 9,
    //       label:
    //         "II.- Se esté tramitando ante el Poder Judicial algún recurso o medio de defensa interpuesto por el recurrente",
    //     },
    //     {
    //       value: 10,
    //       label:
    //         "III.- No actualice alguno de los supuestos previstos en el Artículo 139 de la presente Ley",
    //     },
    //     {
    //       value: 11,
    //       label:
    //         "IV.- No se haya desahogado la prevención en los términos establecidos en el Artículo 141 de la presente Ley",
    //     },
    //     {
    //       value: 12,
    //       label: "V.- Se impugne la veracidad de la información proporcionada",
    //     },
    //     {
    //       value: 13,
    //       label: "VI.- Se trate de una consulta",
    //     },
    //     {
    //       value: 14,
    //       label:
    //         "VII.- El recurrente amplíe su solicitud en el recurso de revisión, únicamente respecto de los nuevos contenidos.",
    //     },
    //   ],
    // },
    {
      label: (
        <p style={{color: "black", fontWeight: "bold", margin: 0}}>
          CONFIRMAR:
        </p>
      ),
      options: [
        {
          value: 15,
          label: "Se confirma la respuesta emitida por el sujeto obligado",
        },
      ],
    },
    {
      label: (
        <p style={{color: "black", fontWeight: "bold", margin: 0}}>
          MODIFICA:
        </p>
      ),
      options: [
        {
          value: 16,
          label:
            "La respuesta entregada y se ordena haga entrega en un término de 10 días hábiles",
        },
      ],
    },
    {
      label: (
        <p style={{color: "black", fontWeight: "bold", margin: 0}}>REVOCA:</p>
      ),
      options: [
        {
          value: 17,
          label: "1.- Por falta de respuesta dentro de los plazos establecidos por la ley, y se le ordena al sujeto obligado, obtener la información de quien la tenga y entregarla al recurrente sin costo alguno, dentro de un plazo de diez días hábiles, contados a partir de la fecha de notificación de esta resolución.",
        },
        {
          value: 18,
          label: "2.- Respuesta emitida es incorrecta, y se le ordena al sujeto obligado, obtener la información de quien la tenga y entregarla al recurrente sin costo alguno, dentro de un plazo de diez días hábiles, contados a partir de la fecha de notificación de esta resolución.",
        },
        {
          value: 19,
          label: "3.- Respuesta emitida no corresponde con lo solicitado, y se le ordena al sujeto obligado, obtener la información de quien la tenga y entregarla al recurrente sin costo alguno, dentro de un plazo de diez días hábiles, contados a partir de la fecha de notificación de esta resolución.",
        },
        {
          value: 20,
          label: "4.- Respuesta entregada en formato distinto al solicitado, y se le ordena al sujeto obligado, obtener la información de quien la tenga y entregarla al recurrente sin costo alguno, dentro de un plazo de diez días hábiles, contados a partir de la fecha de notificación de esta resolución.",
        },
      ],
    },
  ];

  const onChangeSentido = (value) => {
    const sentido = {
      1: {
        sentidoValor: "IMPROCEDENTE",
        setnido:
          "Resulta IMPROCEDENTE La acción ejercitada por el ciudadano en contra del sujeto obligado, en virtud de que este instituto, se encuentra jurídicamente impedido para conocer sobre de la denuncia planteada por ser infundado el escrito de denuncia. ",
      },
      2: {
        sentidoValor: "IMPROCEDENTE",
        sentido:
          "Resulta IMPROCEDENTE La acción ejercitada por el ciudadano en contra del sujeto obligado, en virtud de que SI se encuentran publicadas las obligaciones de transparencia en el portal electrónico según lo establecido en la Ley de Transparencia",
      },
      3: {
        sentidoValor: "PROCEDENTE",
        sentido:
          "Resulta PROCEDENTE La acción ejercitada por el ciudadano en contra del sujeto obligado, en virtud de que no se encuentran publicadas las obligación de transparencia en el portal electrónico según lo establecido en la Ley de Transparencia",
      },
      4: {
        sentidoValor: "SOBRESEER",
        sentido: "I.- El recurrente se desista",
      },
      5: {
        sentidoValor: "SOBRESEER",
        sentido: "II.- El recurrente fallezca",
      },
      6: {
        sentidoValor: "SOBRESEER",
        sentido:
          "III.- Ya que se quedó sin materia. Ello, toda vez que de las constancias que obran en autos, el sujeto obligado envió la información solicitada",
      },
      7: {
        sentidoValor: "SOBRESEER",
        sentido:
          "IV.- Admitido el recurso de revisión, aparezca alguna causal de improcedencia",
      },
      // ? ya no se utiliza
      // 8: { 
      //   sentidoValor: "DESECHAR",
      //   sentido:
      //     "I.- Sea extemporáneo por haber transcurrido el plazo establecido en el Artículo 138 de la presente Ley",
      // },
      // 9: {
      //   sentidoValor: "DESECHAR",
      //   sentido:
      //     "II.- Se esté tramitando ante el Poder Judicial algún recurso o medio de defensa interpuesto por el recurrente",
      // },
      // 10: {
      //   sentidoValor: "DESECHAR",
      //   sentido:
      //     "III.- No actualice alguno de los supuestos previstos en el Artículo 139 de la presente Ley",
      // },
      // 11: {
      //   sentidoValor: "DESECHAR",
      //   sentido:
      //     "IV.- No se haya desahogado la prevención en los términos establecidos en el Artículo 141 de la presente Ley",
      // },
      // 12: {
      //   sentidoValor: "DESECHAR",
      //   sentido: "V.- Se impugne la veracidad de la información proporcionada",
      // },
      // 13: {
      //   sentidoValor: "DESECHAR",
      //   sentido: "VI.- Se trate de una consulta",
      // },
      // 14: {
      //   sentidoValor: "DESECHAR",
      //   sentido:
      //     "VII.- El recurrente amplíe su solicitud en el recurso de revisión, únicamente respecto de los nuevos contenidos.",
      // },
      15: {
        sentidoValor: "CONFIRMAR",
        sentido: "Se confirma la respuesta emitida por el sujeto obligado",
      },
      16: {
        sentidoValor: "MODIFICA",
        sentido:
          "La respuesta entregada y se ordena haga entrega en un término de 10 días hábiles",
      },
      17: {
        sentidoValor: "REVOCA",
        sentido: "1.- Por falta de respuesta dentro de los plazos establecidos por la ley, y se le ordena al sujeto obligado, obtener la información de quien la tenga y entregarla al recurrente sin costo alguno, dentro de un plazo de diez días hábiles, contados a partir de la fecha de notificación de esta resolución.",
      },
      18: {
        sentidoValor: "REVOCA",
        sentido: "2.- Respuesta emitida es incorrecta, y se le ordena al sujeto obligado, obtener la información de quien la tenga y entregarla al recurrente sin costo alguno, dentro de un plazo de diez días hábiles, contados a partir de la fecha de notificación de esta resolución.",
      },
      19: {
        sentidoValor: "REVOCA",
        sentido: "3.- Respuesta emitida no corresponde con lo solicitado, y se le ordena al sujeto obligado, obtener la información de quien la tenga y entregarla al recurrente sin costo alguno, dentro de un plazo de diez días hábiles, contados a partir de la fecha de notificación de esta resolución.",
      },
      20: {
        sentidoValor: "REVOCA",
        sentido: "4.- Respuesta entregada en formato distinto al solicitado, y se le ordena al sujeto obligado, obtener la información de quien la tenga y entregarla al recurrente sin costo alguno, dentro de un plazo de diez días hábiles, contados a partir de la fecha de notificación de esta resolución.",
      },
    };
    setSentido(sentido[value]);
  };

  const optionRespuestaInicial = useMemo(
    () => [
      {
        label: (
          <p style={{color: "black", fontWeight: "bold", margin: 0}}>SI</p>
        ),
        options: [
          {
            value: 1,
            label: "Hizo entrega de la información",
          },
          {
            value: 2,
            label: "Entregó incompleta",
          },
          {
            value: 3,
            label: "Entregó en diferente modalidad al solicitado",
          },
        ],
      },
      {
        label: (
          <p style={{color: "black", fontWeight: "bold", margin: 0}}>NO</p>
        ),
        options: [
          {
            value: 4,
            label:
              "Sujeto obligado fue omiso en responder la solicitud de información",
          },
        ],
      },
      {
        label: (
          <p style={{color: "black", fontWeight: "bold", margin: 0}}>OTRO</p>
        ),
        options: [
          {
            value: 5,
            label:
              "Otro",
          },
        ],
      },
    ],
    []
  );

  const onChangeRespInicial = (value) => {
    const respuesta = {
      1: {
        respuestaInicialValor: "SI",
        respuestaInicial: "Hizo entrega de la información",
      },
      2: {
        value: "SI",
        label: "Entregó incompleta",
      },
      3: {
        value: "SI",
        label: "Entregó en diferente modalidad al solicitado",
      },
      4: {
        value: "NO",
        label:
          "Sujeto obligado fue omiso en responder la solicitud de información",
      },
    };
    setResInicial(respuesta[value]);
  };

  const optionRespuesta = [
    {
      label: (
        <p style={{color: "black", fontWeight: "bold", margin: 0}}>SI</p>
      ),
      options: [
        {
          value: 1,
          label: "Hizo entrega de la información",
        },
        {
          value: 2,
          label: "Entregó incompleta",
        },
        {
          value: 3,
          label: "Entregó en diferente modalidad al solicitado",
        },
        {
          value: 4,
          label: "Reiterar respuesta inicial",
        },
        {
          value: 5,
          label: "Notifica la declinación",
        },
      ],
    },
    {
      label: (
        <p style={{color: "black", fontWeight: "bold", margin: 0}}>NO</p>
      ),
      options: [
        {
          value: 5,
          label:
            "Sujeto obligado fue omiso en entregar el informe justificado.",
        },
      ],
    },
    {
      label: (
        <p style={{color: "black", fontWeight: "bold", margin: 0}}>OTRO</p>
      ),
      options: [
        {
          value: 6,
          label:
            "Otro",
        },
      ],
    },
  ];

  const onChangeRespuesta = (value) => {
    const respuesta = {
      1: {
        respuestaValor: "SI",
        respuesta: "Hizo entrega de la información",
      },
      2: {
        respuestaValor: "SI",
        respuesta: "Entregó incompleta",
      },
      3: {
        respuestaValor: "SI",
        respuesta: "Entregó en diferente modalidad al solicitado",
      },
      4: {
        respuestaValor: "SI",
        respuesta: "Reitera la respuesta inicial",
      },
      5: {
        respuestaValor: "NO",
        respuesta:
          "Sujeto obligado fue omiso en entregar el informe justificado.",
      },
    };
    setOpcionRespuesta(respuesta[value]);
  };

  const breadcrumb = [
    {
      name: "Actas Sesión",
      to: "/actas-sesion",
      icon: <AuditOutlined/>,
    },
    {
      name: `Acta ${model?.titulo}`,
      to: "/actas-sesion/acta-recursos?idActa=" + idActa,
      icon: <AuditOutlined/>,
    },
    {
      name: editando
        ? tipoRecurso === "RECURSO"
          ? `ISTAI-RR-${modelCaso?.numeroExpediente}`
          : `ISTAI-DI-${modelCaso?.numeroExpediente}`
        : tipoRecurso === "RECURSO"
          ? "Creando Recurso"
          : "Creando Denuncia",
      to:
        "/actas-sesion/acta-recursos/" +
        (editando
          ? "detalle?idActa=" + idActa + "&e=" + id
          : "agregar?idActa=" + idActa),
      icon: <AuditOutlined/>,
    },
  ];

  const onConfirmFolio = async (value) => {
    setSaveLoading(true);
    const regex = /^\d+\/\d{4}$/;
    if (!regex.test(value)) {
      message.error("El formato del número/año no es válido. Debe ser X/YYYY.");
      return;
    }

    try {
      let body = {};

      if (id)
        body.caso = id;

      body.idActa = idActa
      body.folio = value;

      const res = await StatusResponse.post('acta-caso/validar-folio', body);
      if (Respuesta(res)) {
        form.submit();
      } else {
        setModalOpen(true);
        setSaveLoading(false)
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSaveLoading(false);
    }


    /*if (confirm.status === 200) {
      setModalInfo(confirm?.resultado[0]);
      setModalOpen(true);
      setSaveLoading(false)
    } else if (confirm.status === 400) {
      form.submit();
    }*/
  };

  const onFinish = async (values) => {
    setSaveLoading(true);
    try {
      const {fechaSolicitud, fechaRecurso, idPonencia, respuesta, expedientesRelacionados} = values;
      const recurso = tipoRecurso === "RECURSO";

      console.log(idPonencia)

      let body = {
        ...values,
        idPonencia: idPonencia.value || idPonencia,
        sujetoDenunciado: values.sujetoDenunciado?.value,
        sujetoObligado: values.sujetoObligado?.value,
        idActa,
        sentidoValor: sentido?.sentidoValor,
        sentido: sentido?.sentido,
        respuestaInicial: respuestaInicial?.value,
        respuestaInicialValor: respuestaInicial?.label,
        respuesta: recurso ? opcionRespuesta?.respuesta : respuesta,
        respuestaValor: opcionRespuesta?.respuestaValor,
        informeVerificacion: informeVerificacion?.informe,
        informeVerificacionValor: informeVerificacion?.informeValor,
        fechaSolicitud: fechaSolicitud
          ? moment(fechaSolicitud)?.format()
          : null,
        fechaRecurso: fechaRecurso ? moment(fechaRecurso)?.format() : null,
        tipo: tipoRecurso,
        referencias: referencias,
        expedientesRelacionados: expedientesRelacionados?.join(',') || null,
      };

      if (!respuestaInicialOtro) {
        body.respuestaInicialOtro = null
      }
      if (!respuestaOtro) {
        body.respuestaOtro = null
      }
      if (!respuestaInicialExtra) {
        body.respuestaInicialExtra = null
      }
      if (!respuestaExtra) {
        body.respuestaExtra = null
      }

      const res = await StatusResponse.post("acta-caso/guardar", body);
      if (Respuesta(res)) {
        form.resetFields();
        history.push(`/actas-sesion/acta-recursos?idActa=${idActa}`);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSaveLoading(false);
    }
  };

  const encontrarMedia = React.useCallback((media) => {
    let _media = [];
    if (media?.length > 0) {
      media.map((item, index) => {
        _media.push(item);
        _media[index].url = item.ruta;
        _media[index].name = item.nombre;
        return true;
      });
    }
    return _media;
  }, []);

  const onChangeTipo = (value) => {
    setTipo(value?.value);
    form.setFieldsValue({
      sujetoObligado: null,
      sujetoDenunciado: null,
    });
  };

  React.useEffect(() => {
    if (modelCaso && Boolean(id)) {
      setTipoRecurso(modelCaso?.tipo);
      if (modelCaso?.tipo === "RECURSO") {
        form.setFieldsValue({
          ...modelCaso,
          fechaSolicitud: modelCaso?.fechaSolicitud
            ? moment(modelCaso?.fechaSolicitud)
            : null,
          fechaRecurso: modelCaso?.fechaRecurso
            ? moment(modelCaso?.fechaRecurso)
            : null,
          sentido: modelCaso?.sentidoValor
            ? `${modelCaso?.sentidoValor}, ${modelCaso?.sentido}`
            : "",
          respuestaInicial: modelCaso?.respuestaInicial
            ? `${modelCaso?.respuestaInicial}, ${modelCaso?.respuestaInicialValor}`
            : "",
          respuesta:
            modelCaso?.respuesta && tipoRecurso === "RECURSO"
              ? modelCaso?.respuesta
                ? `${modelCaso?.respuestaValor}, ${modelCaso?.respuesta}`
                : ""
              : modelCaso?.respuesta
                ? `${modelCaso?.respuesta}`
                : "",
        });
        if (modelCaso?.respuestaInicialOtro?.length > 0) {
          setRespuestaInicialOtro(true)
          form.setFieldsValue({
            respuestaInicial: 5,
          })
        }
        if (modelCaso?.respuestaOtro?.length > 0) {
          setRespuestaOtro(true)
          form.setFieldsValue({
            respuesta: 6,
          })
        }
        if (modelCaso?.respuestaInicialExtra?.length > 0) {
          setRespuestaInicialExtra(true)
          form.setFieldsValue({
            respuestaInicial: 1,
          })
        }
        if (modelCaso?.respuestaExtra?.length > 0) {
          setRespuestaExtra(true)
          form.setFieldsValue({
            respuesta: 1,
          })
        }
      } else {
        form.setFieldsValue({
          ...modelCaso,
          fechaSolicitud: modelCaso?.fechaSolicitud
            ? moment(modelCaso?.fechaSolicitud)
            : null,
          fechaRecurso: modelCaso?.fechaRecurso
            ? moment(modelCaso?.fechaRecurso)
            : null,
          sentido: modelCaso?.sentidoValor
            ? `${modelCaso?.sentidoValor}, ${modelCaso?.sentido}`
            : "",
          respuestaInicial: modelCaso?.respuestaInicial
            ? `${modelCaso?.respuestaInicial}, ${modelCaso?.respuestaInicialValor}`
            : "",
          respuesta: modelCaso?.respuesta ? `${modelCaso?.respuesta}` : ""
        });
      }
      if (modelCaso?.media) {
        const archivos = encontrarMedia(modelCaso?.media);
        setListaArchivos(archivos);
        setReferencias(archivos);
      }

      //seteamos expedientesRelacionados, cadena de texto separada por comas
      const arrExpedientesRelacionados = modelCaso?.expedientesRelacionados?.split(',')
      form.setFieldsValue({
        expedientesRelacionados: arrExpedientesRelacionados?.length > 0 ? arrExpedientesRelacionados : undefined
      });
    }
  }, [
    form,
    id,
    modelCaso,
    optionRespuestaInicial,
    user?.idPonencia,
    encontrarMedia,
    model?.media,
  ]);

  React.useEffect(() => {
    if (user?.idPonencia && !editando) {
      form.setFieldsValue({
        idPonencia: user?.idPonencia,
      });
    }
  }, [editando, form, user?.idPonencia, modelCaso?.ponencia?.nombre]);

  const onfinishFailed = () => {
    let ponencia = form.getFieldValue("idPonencia");
    if (!ponencia) {
      notification.error({
        message: "Ponencia vacía",
        description: "Es necesario seleccionar una ponencia",
      });
      return;
    }
    Modal.confirm({
      title: "¿Desea guardar el acta?",
      content: "Hay campos vacíos.",
      okText: "Guardar",
      cancelText: "Cancelar",
      onOk: () => {
        onFinish(form.getFieldsValue(true));
      },
    });
  };

  if (modelLoading) return <ViewLoading/>;

  return (
    <DefaultLayout breadcrumbItems={breadcrumb} title={`Acta ${model?.titulo}`}>
      <Row gutter={10}>
        <Col span={24}>
          <Row gutter={10} justify="start">
            <Col>
              <Button
                style={tipoRecurso === "RECURSO" ? styleRecurso : styleDefault}
                type="primary"
                onClick={() => setTipoRecurso("RECURSO")}
              >
                Recurso
              </Button>
            </Col>
            <Col>
              <Button
                style={
                  tipoRecurso === "DENUNCIA" ? styleDenuncia : styleDefault
                }
                type="primary"
                onClick={() => setTipoRecurso("DENUNCIA")}
              >
                Denuncia
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Title level={4}>
            {tipoRecurso === "RECURSO" ? "Recurso" : "Denuncia"}
          </Title>
        </Col>
      </Row>
      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onfinishFailed}
        requiredMark={false}
      >
        <Row gutter={10} justify="end">
          <Col sm={24} md={12}>
            <Form.Item name="id" hidden={true}></Form.Item>
            <Form.Item
              label="No. Expediente"
              name="numeroExpediente"
              rules={[
                {
                  pattern: /^\d+\/\d{4}$/,
                  message: "El formato esperado es numero(s)/año",
                },
              ]}
            >
              <Input placeholder="No. Expediente..."/>
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item
              name="idPonencia"
              label="Ponencia"
              rules={[{required: true, message: "Sin seleccionar"}]}
            >
              <Select
                modelsParams={{
                  name: "ponencia",
                  ordenar: "nombre-asc",
                }}
                showSearch
                valueProp={"idPonencia"}
                autoComplete={"off"}
                optionFilterProp="children"
                placeholder="Selecciona una ponencia"
                append={[model?.ponencia]}
                allowClear
                filtrar={rolAdmin ? 0 : 1}
                labelInValue
                render={(_, row) => `${row?.nombre}`}
              />
            </Form.Item>
          </Col>
          {tipoRecurso === "RECURSO" ? (
            <>
              <Col sm={24} md={12}>
                <Form.Item
                  label="Recurrente"
                  name="recurrente"
                  rules={[{required: true, message: "Campo vacío"}]}
                >
                  <Input placeholder="Recurrente..."/>
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  label="Fecha de Solicitud"
                  name="fechaSolicitud"
                  rules={[{required: true, message: "Fecha vacía"}]}
                >
                  <DatePicker
                    style={{width: "100%"}}
                    format="DD/MM/YYYY"
                    placeholder="Selecciona la fecha"
                    locale={locale}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  label="Tipo"
                  rules={[{required: true, message: "Sin seleccionar"}]}
                >
                  <Select
                    modelsParams={{
                      name: "ambito",
                      ordenar: "titulo-asc",
                    }}
                    showSearch
                    valueProp={"id"}
                    optionFilterProp="children"
                    placeholder="Seleccionar"
                    allowClear
                    labelInValue
                    render={(_, row) => `${row?.titulo}`}
                    onChange={(value) => onChangeTipo(value)}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  label="Sujeto Obligado"
                  name="sujetoObligado"
                  rules={[{required: true, message: "Sin seleccionar"}]}
                >
                  <Select
                    modelsParams={{
                      name: "ente",
                      ordenar: "titulo-asc",
                    }}
                    showSearch
                    valueProp={"titulo"}
                    optionFilterProp="children"
                    placeholder="Seleccionar Sujeto Obligado"
                    allowClear
                    filtrar={tipo}
                    labelInValue
                    render={(_, row) => `${row?.titulo}`}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Solicita" name="solicita">
                  <Input.TextArea rows={6}/>
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  label="Fecha del Recurso"
                  name="fechaRecurso"
                  rules={[{required: true, message: "Fecha vacía"}]}
                >
                  <DatePicker
                    style={{width: "100%"}}
                    format="DD/MM/YYYY"
                    placeholder="Selecciona la fecha"
                    locale={locale}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  label="Litis"
                  name="litis"
                  rules={[{required: true, message: "Sin seleccionar"}]}
                >
                  <NormalSelect
                    options={optionLitisRecurso}
                    placeholder="I.- La clasificación de información..."
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label="Respuesta Inicial"
                  name="respuestaInicial"
                  rules={[{required: true, message: "Sin seleccionar"}]}
                >
                  <NormalSelect
                    options={optionRespuestaInicial}
                    onSelect={(value) => {
                      onChangeRespInicial(value)
                      if (value === 5) {
                        setRespuestaInicialOtro(true)
                        setRespuestaInicialExtra(false)
                      } else if (value === 1) {
                        setRespuestaInicialExtra(true)
                        setRespuestaInicialOtro(false)
                        form.setFieldsValue({
                          respuestaInicialOtro: null,
                        })
                      } else {
                        setRespuestaInicialExtra(false)
                        setRespuestaInicialOtro(false)
                        form.setFieldsValue({
                          respuestaInicialOtro: null,
                          respuestaInicialExtra: null,
                        })
                      }
                    }}
                    placeholder="Seleccionar una respuesta inicial"
                  />
                </Form.Item>
              </Col>
              {
                respuestaInicialExtra && (
                  <Col span={24} md={12}>
                    <Form.Item label="Respuesta Inicial Manifiesto" name="respuestaInicialExtra">
                      <Input.TextArea
                        rows={1}
                        placeholder="Manifiesto..."
                        maxLength={200}
                        showCount
                      />
                    </Form.Item>
                  </Col>
                )
              }
              {
                respuestaInicialOtro && (
                  <Col span={24} md={12}>
                    <Form.Item label="Respuesta Inicial Otro" name="respuestaInicialOtro">
                      <Input.TextArea
                        rows={1}
                        placeholder="Otro..."
                        maxLength={200}
                        showCount
                      />
                    </Form.Item>
                  </Col>
                )
              }
              <Col span={24} md={12}>
                <Form.Item
                  label="Informe Justificado"
                  name="respuesta"
                  rules={[{required: true, message: "Sin seleccionar"}]}
                >
                  <NormalSelect
                    options={optionRespuesta}
                    onSelect={(value) => {
                      onChangeRespuesta(value)
                      if (value === 6) {
                        setRespuestaOtro(true)
                        setRespuestaExtra(false)
                      } else if (value === 1) {
                        setRespuestaExtra(true)
                        setRespuestaOtro(false)
                        form.setFieldsValue({
                          respuestaOtro: null,
                        })
                      } else {
                        setRespuestaExtra(false)
                        setRespuestaOtro(false)
                        form.setFieldsValue({
                          respuestaOtro: null,
                          respuestaExtra: null,
                        })
                      }
                    }}
                    placeholder="Seleccionar una opción"
                  />
                </Form.Item>
              </Col>
              {
                respuestaExtra && (
                  <Col span={24} md={12}>
                    <Form.Item label="Informe Justificado Manifiesto" name="respuestaExtra">
                      <Input.TextArea
                        rows={1}
                        placeholder="Manifiesto..."
                        maxLength={200}
                        showCount
                      />
                    </Form.Item>
                  </Col>
                )
              }
              {
                respuestaOtro && (
                  <Col span={24} md={12}>
                    <Form.Item label="Informe Justificado Otro" name="respuestaOtro">
                      <Input.TextArea
                        rows={1}
                        placeholder="Otro..."
                        maxLength={200}
                        showCount
                      />
                    </Form.Item>
                  </Col>
                )
              }
              <Col sm={24} md={12}>
                <Form.Item
                  label="Sentido"
                  name="sentido"
                  rules={[{required: true, message: "Sin seleccionar"}]}
                >
                  <NormalSelect
                    options={optionsSentidoRecurso}
                    onSelect={(valor) => onChangeSentido(valor)}
                    placeholder="Selecciona el sentido"
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  label="Vista al OIC"
                  name="vistaOic"
                  rules={[{required: true, message: "Sin seleccionar"}]}
                >
                  <NormalSelect options={optionsVistaOic}/>
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  label="Expedientes Relacionados"
                  name="expedientesRelacionados"
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (value?.length > 0) {
                          const regex = /^\d+\/\d{4}$/;
                          let error = false;
                          value.map((item) => {
                            if (!regex.test(item)) {
                              error = true;
                            }
                            return true;
                          });
                          if (error) {
                            return Promise.reject(
                              "El formato del número/año no es válido. Debe ser X/YYYY."
                            );
                          }
                        }
                      },
                    },
                  ]}
                  extra="Formato: X/YYYY, separados por coma (,) ejemplo: 123/2021, 456/2021"
                >
                  <NormalSelect
                    mode="tags"
                    style={{width: '100%'}}
                    tokenSeparators={[',']}
                    placeholder="Expertos relacionados..."
                  />
                </Form.Item>
              </Col>
              {(modelCaso && modelCaso?.idPonencia === user?.idPonencia) ||
              !editando ||
              rolAdmin ? (
                <Col span={24}>
                  {
                    ver ? (
                        <Form.Item>
                          <Button
                            block
                            loading={saveLoading}
                            type="primary"
                            disabled={model?.estatus === "CERRADO"}
                            onClick={() => {
                              onConfirmFolio(form.getFieldValue("numeroExpediente"))
                            }}
                          >
                            Guardar
                          </Button>
                        </Form.Item>
                      )
                      :
                      (
                        null
                      )
                  }
                </Col>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <Col sm={24} md={12}>
                <Form.Item
                  label="Tipo"
                  rules={[{required: true, message: "Sin seleccionar"}]}
                >
                  <Select
                    modelsParams={{
                      name: "ambito",
                      ordenar: "titulo-asc",
                    }}
                    showSearch
                    valueProp={"id"}
                    optionFilterProp="children"
                    placeholder="Seleccionar"
                    allowClear
                    labelInValue
                    render={(_, row) => `${row?.titulo}`}
                    onChange={(value) => onChangeTipo(value)}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  label="Sujeto Obligado"
                  name="sujetoDenunciado"
                  rules={[{required: true, message: "Sin seleccionar"}]}
                >
                  <Select
                    modelsParams={{
                      name: "ente",
                      ordenar: "titulo-asc",
                    }}
                    showSearch
                    valueProp={"titulo"}
                    optionFilterProp="children"
                    placeholder="Seleccionar Sujeto Obligado"
                    allowClear
                    filtrar={tipo}
                    labelInValue
                    render={(_, row) => `${row?.titulo}`}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  label="Denunciante"
                  name="denunciante"
                  rules={[{required: true, message: "Campo vacío"}]}
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  label="Fecha de Denuncia"
                  name="fechaSolicitud"
                  rules={[{required: true, message: "Fecha sin seleccionar"}]}
                >
                  <DatePicker
                    style={{width: "100%"}}
                    format="DD/MM/YYYY"
                    placeholder="Selecciona la fecha"
                    locale={locale}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Litis"
                  name="litis"
                  rules={[{required: true, message: "Campo vacío"}]}
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Denuncia" name="denuncia">
                  <Input.TextArea rows={6}/>
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label="Informe de Verificación"
                  name="informeVerificacion"
                  rules={[{required: true, message: "Sin seleccionar"}]}
                >
                  <NormalSelect
                    options={optionsInformeVerificacion}
                    onSelect={(valor) => onChangeInforme(valor)}
                    placeholder="Seleccionar un informe de verificacion"
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label="Informe Justificado"
                  name="respuesta"
                  rules={[{required: true, message: "Sin seleccionar"}]}
                >
                  <Input placeholder="Ingresar un informe."/>
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label="Sentido"
                  name="sentido"
                  rules={[{required: true, message: "Sin seleccionar"}]}
                >
                  <NormalSelect
                    placeholder="Selecciona el sentido"
                    options={optionsSentido}
                    onSelect={(valor) => onChangeSentido(valor)}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label="Vista al OIC"
                  name="vistaOic"
                  rules={[{required: true, message: "Sin seleccionar"}]}
                >
                  <NormalSelect options={optionsVistaOic}/>
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  label="Expedientes Relacionados"
                  name="expedientesRelacionados"
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (value?.length > 0) {
                          const regex = /^\d+\/\d{4}$/;
                          let error = false;
                          value.map((item) => {
                            if (!regex.test(item)) {
                              error = true;
                            }
                            return true;
                          });
                          if (error) {
                            return Promise.reject(
                              "El formato del número/año no es válido. Debe ser X/YYYY."
                            );
                          }
                        }
                      },
                    },
                  ]}
                  extra="Formato: X/YYYY, separados por coma (,) ejemplo: 123/2021, 456/2021"
                >
                  <NormalSelect
                    mode="tags"
                    style={{width: '100%'}}
                    tokenSeparators={[',']}
                    placeholder="Expertos relacionados..."
                  />
                </Form.Item>
              </Col>
              <Divider/>
              {(model && modelCaso?.idPonencia === user?.idPonencia) ||
              !editando ||
              rolAdmin ? (
                <Col span={4}>
                  <Form.Item>
                    <Button
                      block
                      loading={saveLoading}
                      disabled={model?.estatus === "CERRADO"}
                      type="primary"
                      onClick={() =>
                        onConfirmFolio(form.getFieldValue("numeroExpediente"))
                      }
                    >
                      Guardar
                    </Button>
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}
            </>
          )}
        </Row>
        <Divider orientation="left">Archivos Adjuntos</Divider>
        <Row>
          <Uploader
            endPoint={"subir-archivo"}
            endPointEliminar={"acta-caso/eliminar-media"}
            setReferencias={setReferencias}
            setListaArchivos={setListaArchivos}
            listaArchivos={listaArchivos}
          />
        </Row>
      </Form>
      <Modal
        footer={[
          <Row gutter={10} justify="space-between">
            <Col>
              <Button
                key="back"
                type="danger"
                onClick={() => setModalOpen(false)}
              >
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                key="submit"
                htmlType="submit"
                type="primary"
                onClick={() => onFinish(form.getFieldsValue(true))}
              >
                Continuar
              </Button>
            </Col>
          </Row>,
        ]}
        title="Folio Existente"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
      >
        <Row justify="center">
          <Col>
            <Title
              level={5}
            >{`El Folio ${modalInfo?.numeroExpediente} ya existe en el acta ${modalInfo?.acta?.titulo}`}</Title>
          </Col>
          <Col>
            <Title level={5}>¿Desea continuar?</Title>
          </Col>
        </Row>
      </Modal>
    </DefaultLayout>
  );
};

export default DenunciaDetalle;
