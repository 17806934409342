import React, { useMemo, useRef } from "react";
import { Descriptions, Divider, Modal } from "antd";
import {
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  PlusCircleOutlined,
  UserOutlined
} from "@ant-design/icons";
import { DropdownOverlayButton } from "../../../components";
import SimpleTableLayout from "../../../components/layouts/SimpleTableLayout";
import { useHistory } from "react-router-dom"
import { capitalizeFirst } from "../../../utilities";
import StatusResponse from "../../../services/statusResponse";
import TablaComponent from "../../../components/TablaComponent";

const viewTitle = "Usuarios";
const searchPlaceholder = "Búsqueda...";
const emptyText = "No hay usuarios registrados...";

export const UsuarioLista = () => {
  const history = useHistory()
  const [search, setSearch] = React.useState("")
  const [searchLoading, setSearchLoading] = React.useState(false)
  const tablaRef = useRef(null);

  const breadcrumb=[
    {
      name: "Usuarios",
      to: "/administracion/usuarios",
      icon: <UserOutlined />
    }
  ];

  const buttonData = {
    text: "Nuevo Usuario",
    icon: <PlusCircleOutlined /> ,
    to: () => history.push("/administracion/usuarios/nuevo")
  }


  const sinDefinir = <i>-Sin definir-</i>

  const renderSimple = (prop) => prop || sinDefinir;

  const modalDelete = row => {
    if(!row?.id) return;
    Modal.confirm({
      title: "Eliminar Usuario",
      content: `¿Está seguro de eliminar al usuario "${row?.nombre}"?`,
      icon: <DeleteOutlined style={{ color: '#ff0000' }}/>,
      okText: 'Eliminar',
      okButtonProps: {
        type: 'danger',
      },
      onCancel: () => {},
      cancelText: 'Cancelar',
      onOk: () => {
        return new Promise(async (resolve, reject) => {
          const res = await StatusResponse.delete("usuario", { id: row?.id})
          if (res.status === 200) {
            tablaRef.current.refresh();
            let secondsToGo = 2
            const modal = Modal.success({
              title: res?.mensaje,
              cancelButtonProps: { style: { display: 'none' } },
              okButtonProps: { style: { display: 'none' } }
            })
            const timer = setInterval(() => {secondsToGo -= 1}, 1000)
            setTimeout(() => {
              clearInterval(timer)
              modal.destroy()
            }, secondsToGo * 1000)
            resolve()
          }
          reject()
        })
      }
    });
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 40,
      render: renderSimple
    },
    {
      title: "Nombre",
      width: 100,
      dataIndex: "nombre",
      key: "nombre",
      ellipsis: true,
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Correo",
      width: 150,
      dataIndex: "correo",
      key: "correo",
      ellipsis: true,
      render: renderSimple
    },
    {
      title: "Rol",
      width: 100,
      dataIndex: "rol",
      key: "rol",
      ellipsis: true,
      render: capitalizeFirst
    },
    {
      title: "Ente",
      width: 150,
      dataIndex: "ente",
      key: "ente",
      ellipsis: true,
      render: (prop) => prop ? prop?.titulo : sinDefinir
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 70,
      render: (_, row) => (
        <DropdownOverlayButton
          options={[
            {
              name: "Ver detalle",
              icon: <EyeOutlined />,
              onClick: () => modalInfo(row)
            },
            {
              name: "Editar información",
              icon: <EditOutlined />,
              onClick: () => history.push(`usuarios/detalle?id=${row?.id}`),
              styleProps: {
                color: "#4495bb"
              }
            },
            {
              name: "Eliminar",
              icon: <DeleteOutlined />,
              onClick: () => modalDelete(row),
              styleProps: {
                color: "#f5222d"
              }
            }
          ]}
        />
      ),
    },
  ];

  const ContentModal = ({row}) => {
    console.log(row);
    return (
      <div>
        <Descriptions
          bordered
          column={1}
          size="small"
          title="Información de la categoría"
        >
          <Descriptions.Item labelStyle={{"fontWeight": "bold"}} label="ID">{row?.id || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"fontWeight": "bold"}} label="Nombre">{row?.nombre || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"fontWeight": "bold"}} label="Correo">{row?.correo || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"fontWeight": "bold"}} label="Rol">{row?.rol || sinDefinir}</Descriptions.Item>
        </Descriptions>
        <Divider />
        <Descriptions
          bordered
          column={1}
          size="small"
          title="Entidad asociada"
        >
          <Descriptions.Item labelStyle={{"fontWeight": "bold"}} label="ID">{row?.ente?.id || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"fontWeight": "bold"}} label="Ente">{row?.ente?.titulo || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"fontWeight": "bold"}} label="Ámbito">{row?.ente?.ambito || sinDefinir}</Descriptions.Item>
        </Descriptions>
      </div>
    )
  }

  const modalInfo = (v) => {

    Modal.confirm({
      title: "",
      icon: null,
      content: <ContentModal row={v}/>,
      okText: "Cerrar",
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { primary: true },
      closable: true,
      width:1000
    });
  };

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    setSearch(v);
    setSearchLoading(false);
  },[]);


  const extraParams = useMemo(() => {
    return {q: search}
  }, [search]);


  return (
    <SimpleTableLayout
      buttonData={buttonData}
      breadcrumbItems={breadcrumb}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearch}
      emptyText={emptyText}
    >
      <TablaComponent 
        nameURL={'usuario'}
        columns={columns}
        order={'id-desc'}      
        expand={'ente, permiso'}
        extraParams={extraParams}
        innerRef={tablaRef}
      />
    </SimpleTableLayout>
  );
};