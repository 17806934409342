import {Content} from "antd/lib/layout/layout";
import {BreadCrumb, HeaderPage} from "../";
import {Helmet} from 'react-helmet';
import {ConfigProvider} from "antd";
import locale from "antd/es/locale/es_ES";

const DefaultLayout = ({
                         children,
                         breadcrumbItems,
                         buttonData,
                         title,
                         breadcrumbDisable = false,
                       }) => {
  const styles = {
    padding: {
      marginLeft: 15,
      marginRight: 15,
    },
    content: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
      minHeight: 280,
      borderRadius: 5,
    },
  };

  return (
    <div style={styles.padding}>
      <Helmet>
        <title>
          {title || "INSTITUTO SONORENSE DE TRANSPARENCIA ACCESO A LA INFORMACIÓN PÚBLICA Y PROTECCIÓN DE DATOS PERSONALES"}
        </title>
      </Helmet>
      {!breadcrumbDisable && <BreadCrumb breadcrumbItems={breadcrumbItems}/>}

      <HeaderPage title={title} buttonData={buttonData}/>

      <ConfigProvider locale={locale}>
        <Content className="site-layout-background" style={styles.content}>
          {children}
        </Content>
      </ConfigProvider>
    </div>
  );
};

export default DefaultLayout;