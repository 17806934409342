import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  message,
  Row,
  Select as AntdSelect,
  Typography,
  Divider,
  Checkbox,
  Popover,
} from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  SaveOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import DefaultLayout from "../../../components/layouts/DefaultLayout";
import { useModel, useModels, useQuery } from "../../../hooks";
import { Select, ViewLoading } from "../../../components";
import StatusResponse from "../../../services/statusResponse";
import { roles } from "../../../constants";

const { Text, Title } = Typography;

export const UsuarioDetalle = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const titulo = editing ? "Editar Usuario" : "Agregar Usuario";
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState({});
  const [requestEntes, setRequestEntes] = useState({});
  const [permisosUsuario, setPermisosUsuario] = useState([]);

  const { model, modelLoading } = useModel(request);

  const [entes, entesLoading] = useModels(requestEntes);

  const [permisos, permisosLoading] = useModels({
    name: "permiso",
    expand: "permisoModulo",
    limite: 100,
  });

  const [permisoModulo, permisoModuloLoading] = useModels({
    name: "permiso-modulo",
  });

  const chequearModulo = (e) => {
    let _permiso = e.target.value.split("-");
    let _idPermisoModulo = parseInt(_permiso[1]);

    const permisosForm = [];
    const _permisos = form.getFieldValue("permisos");

    for (let i = 0; i < _permisos?.length; i++) {
      if (!permisosForm.includes(_permisos[i])) {
        permisosForm.push(_permisos[i]);
      }
    }

    for (let i = 0; i < permisos?.length; i++) {
      if (_idPermisoModulo !== permisos[i]?.idPermisoModulo) {
        continue;
      }

      if (e.target.checked && !permisosForm.includes(permisos[i]?.idPermiso)) {
        permisosForm.push(permisos[i]?.idPermiso);
      }

      if (!e.target.checked && permisosForm.includes(permisos[i]?.idPermiso)) {
        permisosForm.splice(permisosForm.indexOf(permisos[i]?.idPermiso), 1);
      }
    }

    if (e.target.checked) {
      permisosForm.push(e.target.value);
    } else {
      permisosForm.splice(permisosForm.indexOf(e.target.value), 1);
    }

    setPermisosUsuario(permisosForm);
  };

  useEffect(() => {
    setRequestEntes({
      name: "ente",
      limite: -1,
    });
    return () => {
      setRequestEntes({});
    };
  }, []);

  useEffect(() => {
    setRequest({
      name: "usuario",
      id: id,
      expand: "ente,enteUsuario,permiso",
    });
    return () => {
      setRequest({});
    };
  }, [id]);

  const breadcrumbItems = [
    {
      name: "Usuarios",
      to: "/administracion/usuarios",
      icon: <UserOutlined />,
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: editing
        ? "/administracion/usuarios/detalle?id=" + id
        : "/administracion/usuarios/nuevo",
      icon: <EditOutlined />,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push("/administracion/usuarios"),
    icon: <ArrowLeftOutlined />,
    props: { disabled: false, type: "primary" },
  };

  const onFinish = async (values) => {
    const { clave, confirmarClave } = values;
    console.log(values)
    if (confirmarClave !== clave) {
      Modal.warning({
        title: "Error",
        content: "Las contraseñas no coinciden, intente de nuevo.",
      });
      return;
    }

    let body = {...values, idPonencia: values?.idPonencia?.key};

    try {
      setLoading(true);

      if (editing) {
        body.id = id;
        const esOtroEnte = Number(values.idEnte) !== Number(model?.ente?.id);

        if (esOtroEnte) {
          const actualizarEnteUsuario = await StatusResponse.post(
            "ente-usuario",
            {
              idEnte: body.idEnte,
              idUsuario: id,
              id: model?.enteUsuario?.id,
            }
          );

          if (actualizarEnteUsuario.status !== 200) {
            message.error("No se pudo actualizar el ente del usuario");
            return;
          }
        }

        delete body.idEnte; // ? Esto evita guardados múltiples al editar

        if (clave && confirmarClave) {
          const cambiarClave = await StatusResponse.post(
            "usuario/cambiar-clave",
            {
              idUsuario: id,
              clave: clave,
            }
          );

          if (cambiarClave.status !== 200) {
            message.error("No se pudo cambiar la contraseña");
            return;
          }
          delete body.clave; // ? Esto evita guardados múltiples al editar
        }
      }

      const res = await StatusResponse.post("usuario", body);
      if (res) {
        if (res?.status === 400 && res?.errores !== null) {
          const newArray = Object.values(res?.errores);
          Modal.error({
            title: res?.mensaje,
            content: (
              <div>
                {newArray.map((m, i) => (
                  <span key={i + 1}>
                    {" "}
                    -{m} <br />
                  </span>
                ))}
              </div>
            ),
          });
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: { marginTop: "20vh" },
          });
        } else if (res?.status === 200) {
          message.success({
            content: res?.mensaje,
            style: { marginTop: "20vh" },
          });
          history.push("/administracion/usuarios");
        }
      }
    } catch (e) {
      console.log("Error al guardar: ", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (model && !modelLoading) {
      let valores = model?.permiso?.map((p) => p.idPermiso);
      form.setFieldsValue({
        ...model,
        idEnte: model?.ente?.id,
        permisos: valores,
      });
    }
  }, [form, modelLoading, model]);

  if (modelLoading) return <ViewLoading />;

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      <Form
        autoComplete="off"
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={[16, 0]}>
          <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xxl={8}>
            <Form.Item
              hasFeedback
              label="Nombre"
              name="nombre"
              rules={[
                { required: true, message: "Por favor ingrese el nombre" },
              ]}
            >
              <Input placeholder="Escriba el nombre" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xxl={8}>
            <Form.Item
              hasFeedback
              label="Correo"
              name="correo"
              rules={[
                { required: true, message: "Por favor ingrese el correo" },
              ]}
            >
              <Input placeholder="Escriba el correo" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xxl={8}>
            <Form.Item
              label="Rol"
              name="rol"
              rules={[
                { required: true, message: "Por favor seleccione un rol" },
              ]}
            >
              <AntdSelect options={roles} placeholder="Seleccione un rol" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xxl={8}>
            <Form.Item
              label="Ente"
              name="idEnte"
              rules={[
                { required: true, message: "Por favor seleccione un ente" },
              ]}
            >
              <AntdSelect
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                autoComplete="new-password"
                loading={entesLoading}
                options={
                  entes
                    ? entes
                        .sort((a, b) => (a?.titulo > b?.titulo ? 1 : -1))
                        .map((ente) => ({
                          label: ente?.titulo,
                          value: ente?.id,
                          key: ente?.id,
                        }))
                    : []
                }
                placeholder="Seleccione un ente"
              />
            </Form.Item>
          </Col>
          <Col span={24} md={8}>
            <Form.Item label="Ponencia" name="idPonencia">
              <Select
                modelsParams={{ name: "ponencia", ordenar: "nombre-asc" }}
                showSearch
                valueProp={"idPonencia"}
                autoComplete={"off"}
                optionFilterProp="children"
                placeholder="Selecciona una ponencia"
                allowClear
                labelInValue
                render={(_, row) => `${row?.nombre}`}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={24}>
            {editing ? (
              <Title level={5}>
                {" "}
                Cambiar Contraseña{" "}
                <Text type="secondary">
                  {" "}
                  - (Llene los siguientes campos sólo en caso de cambiar la
                  contraseña) -{" "}
                </Text>
              </Title>
            ) : (
              <Title level={5}> Crear Contraseña </Title>
            )}
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xxl={8}>
            <Form.Item
              name="clave"
              label="Contraseña"
              hasFeedback
              rules={[
                {
                  required: !editing ? true : false,
                  message: "Esta campo es obligatorio.",
                },
              ]}
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xxl={8}>
            <Form.Item
              name="confirmarClave"
              label="Confirmar Contraseña"
              hasFeedback
              rules={[
                {
                  required: !editing ? true : false,
                  message: "Esta campo es obligatorio.",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Row
        //hidden={!user?.permisoExtra?.includes(18)}
        >
          <Divider
            orientation="center"
            style={{ color: "#333", fontWeight: "bold" }}
          >
            <Title level={3}>Permisos</Title>
          </Divider>
          <Col span={24}>
            <Form.Item name="permisos">
              {/* {permisoSelect} */}

              <Checkbox.Group>
                {permisoModulo.map((modulo, _) => (
                  <React.Fragment key={`modulo ${modulo?.idPermisoModulo} `}>
                    <Divider>
                      <Checkbox
                        value={"modulo-" + modulo?.idPermisoModulo}
                        onChange={(e) => chequearModulo(e)}
                      >
                        {modulo?.nombre}
                      </Checkbox>
                    </Divider>
                    {permisos
                      .filter(
                        (permiso) =>
                          permiso.idPermisoModulo === modulo?.idPermisoModulo
                      )
                      .map((permiso, _) => (
                        <React.Fragment key={`permiso ${permiso?.idPermiso}`}>
                          <Checkbox
                            // onChange={(e) => chequearPermiso(permiso)}
                            value={permiso?.idPermiso}
                          >
                            <Popover
                              placement="right"
                              content={permiso?.descripcion}
                            >
                              {permiso?.permiso}
                            </Popover>
                          </Checkbox>
                          <br />
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined />}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DefaultLayout>
  );
};
