import { Button, Col, Divider, Form, Input, message, Modal, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {Select, ViewLoading} from "../../../components";
import { DefaultLayout } from "../../../components/layouts";
import { useModel, useQuery } from "../../../hooks";
import StatusResponse from "../../../services/statusResponse";
import { ArrowLeftOutlined, SaveOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";



const PonenciaDetalle = () =>{

  const titulo = "Ponencias";
  const [form] = Form.useForm();
  const history = useHistory();

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const [loading, setLoading] = useState(false);
  const [estatus, setEstatus] = useState(false);

  const [areasTabla, setAreasTabla] = useState([]);

  const [selectorAreas, setSelectorAreas] = useState("");
  const { model, modelLoading } = useModel({
    name: "ponencia",
    // expand: "areas",
    id: id,
  });

  const columnasTabla = [
    {
      title: "#",
      key: "idArea",
      dataIndex: "idArea",
      render: (_, item) => item?.idArea
    },
    {
      title: "Nombre",
      key: "nombre",
      dataIndex: "nombre",
      render: (_, item) => item?.nombre
    },
    {
      title: "Acciones",
      key: "id",
      dataIndex: "id",
      width: "50px",
      align: "center",
      render: (_, item) => (
        <Button
          key={item?.id}
          icon={<CloseOutlined />}
          onClick={() => setAreasTabla(onDelete(areasTabla, item?.idArea))}
        />
      ),
    },
  ];

  const breadcrumbItems = [
    {
      name: "Ponencias",
      to: "/administracion/ponencias/",
    },
    {
      name: editing ? "Editar" : "Nueva",
      to: `/administracion/ponencias/${editing ? `editar?id=${id}` : "nueva"}`,
    },
  ];

  const agregarElemento = (arreglo, elemento) => {
    if (!elemento || !arreglo) return arreglo;
    let exit = false;

    arreglo.forEach((arrItem) => {
      if (elemento?.idArea === arrItem?.idArea) {
        Modal.error({
          content: "No se pueden agregar elementos repetidos"
        });
        exit = true;
      }
    });

    if (exit) return arreglo;
    arreglo = [...arreglo, elemento];
    return arreglo;
  };

  const onDelete = (arreglo, id) => {
    arreglo = arreglo.filter((item) => item?.idArea !== id);
    return arreglo;
  };

  const buttonData = {
    text: "Volver",
    to: () => history.push("/administracion/ponencias/"),
    icon: <ArrowLeftOutlined />,
    props: { disabled: false, type: "primary" },
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      form.setFieldsValue({
        ...model,
        nombre: model?.nombre,
      });
      setAreasTabla(model?.areas)
      setEstatus(model.estatus === 1 ? true : false);
      console.log(model?.nombre);
    }
    return () => (mounted = false);
  }, [form, modelLoading, model]);

  const onFinish = async (values) => {

    let body = {
      ...values,
      areas: areasTabla
    };
    if (editing) {
      body.idPonencia = id;
    }
    body.estatus = estatus === true ? 1 : 0;
    try {
      setLoading(true);
      const res = await StatusResponse.post("ponencia/guardar", body);
      if (res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          Modal.error({
            title: "Error al guardar: datos incompletos.",
          });
          console.log(arrayError);
          //cuando el dato ya existe no se puede guardar en BD
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: { marginTop: "20vh" },
          });
          //todo salió bien
        } else if (res?.status === 200) {
          message.success({
            content: res?.mensaje,
            style: { marginTop: "20vh" },
          });
          history.push("/administracion/ponencias");
        }
      }
    } catch (e) {
      console.log("Error al guardar: ", e);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    message.warning("Error al guardar: datos incompletos.");
    console.log(values, errorFields, outOfDate);
  };

  if (modelLoading) return <ViewLoading />;

  return(
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
         <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
         <Col
            className="gutter-row"
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          >
            <Form.Item
              label="Nombre Ponencia"
              name="nombre"
              rules={[{ required: true, message: 'Requerido' }]}
            >
              <Input 
                placeholder="Escribir nombre ponencia"
                autoComplete="off"
              />  
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          >
            <Form.Item
              label="Clave Ponencia"
              name="clave"
              rules={[{ required: true, message: 'Requerido' }]}
            >
              <Input 
                placeholder="Escribir clave de Ponencia"
                autoComplete="off"
              />  
            </Form.Item>
          </Col>
         </Row>
         {/* <Row gutter={10}>
            <Col xs={24} sm={24} md={24} lg={24} xg={24}>
              <Row>
                <h4>Áreas</h4>
              </Row>
              <Row>
                <Col xs={21} sm={21} md={21} lg={21} xg={21}>
                  <Select
                    modelsParams={{
                      name: "area",
                      ordenar: "nombre-asc",
                      expand: "",
                    }}
                    valueProp={"idArea"}
                    autoComplete={"off"}
                    optionFilterProp="children"
                    placeholder="Selecciona un área"
                    allowClear
                    labelInValue
                    onChange={(_, row) => {
                      row.idProducto = row?.id;
                      setSelectorAreas(row);
                    }}
                    render={(_, row) => `${row.nombre}`}
                  />
                </Col>
                <Col xs={3} sm={3} md={3} lg={3} xg={3}>
                  <Button
                    icon={<PlusOutlined />}
                    block
                    type="primary"
                    onClick={(_, row) =>
                      setAreasTabla(
                        agregarElemento(areasTabla, selectorAreas)
                      )
                    }
                  ></Button>
                </Col>
                <Col span={24}>
                  <Table
                    columns={columnasTabla}
                    dataSource={areasTabla}
                    pagination={false}
                    rowKey={"idArea"}
                    size="small"
                  />
                </Col>
              </Row>
            </Col>
          </Row> */}
          <Divider></Divider>
         <Row gutter={{xs:10,sm:10,md:10,lg:10}}>
          <Col
            xs={{span:24,offset:0}}
            sm={{span:24,offset:0}}
            md={{span:6,offset:18}}
            lg={{span:6,offset:18}}
            xxl={{span:6,offset:18}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined />}
              >Guardar</Button>
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </DefaultLayout>
  );
}

export default PonenciaDetalle;