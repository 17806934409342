import React from 'react';
import {DefaultLayout} from '../../components/layouts';
import {Form, Row, Col} from 'antd';
import {ArrowLeftOutlined, AuditOutlined} from '@ant-design/icons';
import {useModel, useQuery} from '../../hooks';
import {ViewLoading} from '../../components';
import moment from 'moment';
import {useHistory} from 'react-router-dom';

const ActaScriptSesion = () => {

  const history = useHistory();
  const q = useQuery();

  const id = q.get('id');
  const editando = Boolean(id);

  const [form] = Form.useForm();

  const [request, setRequest] = React.useState({});

  const requestParams = React.useMemo(() => ({
    name: 'acta',
    id: id
  }), [id]);

  const {
    model,
    modelLoading
  } = useModel(request)

  const breadcrumb = [
    {
      name: "Actas Sesión",
      to: "/actas-sesion",
      icon: <AuditOutlined/>
    },
    {
      name: editando ? `${model?.titulo}` : 'Nueva',
      to: "/actas-sesion",
      icon: <AuditOutlined/>
    }
  ];

  const butonData = {
    text: "Volver",
    icon: <ArrowLeftOutlined/>,
    to: () => history.goBack(),
  }

  React.useEffect(() => {
    setRequest(requestParams);
    return () => {
      setRequest({})
    }
  }, [requestParams]);

  React.useEffect(() => {
    if (editando && model) {
      form.setFieldsValue({
        ...model,
        fecha: model?.fecha ? moment(model?.fecha) : null
      });
    }
  }, [editando, form, model]);

  if (modelLoading) return <ViewLoading/>

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={`Script de Sesión ${model?.titulo}`}
      buttonData={butonData}
    >
      <Row gutter={10}>
        <Col span={24}>
          <iframe
            src={'https://intranet.api.transparenciasonora.org/word/acta/auxiliar-sesion?id=' + id + '&raw=1'}
            style={{width: '100%', height: '700px', padding: '0 10%'}}
          />
        </Col>
      </Row>
    </DefaultLayout>
  )
}

export default ActaScriptSesion