import React, { useMemo } from "react";
import {Descriptions, Modal} from "antd";
import {
  EyeOutlined,
  ReconciliationOutlined,
  EditOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import {DropdownOverlayButton} from "../../../components";
import SimpleTableLayout from "../../../components/layouts/SimpleTableLayout";
import {useHistory} from "react-router-dom"
import {paginas} from "../../../constants";
import TablaComponent from "../../../components/TablaComponent";

const viewTitle = "Categorías de Documentos";
const searchPlaceholder = "Buscar por título, descripción, etc.";
const emptyText = "No hay categorías registradas...";

export const PortalCategoriaLista = () => {
  const history = useHistory()
  const [search, setSearch] = React.useState("")
  const [searchLoading, setSearchLoading] = React.useState(false)

  const breadcrumb = [
    {
      name: "Categorías de Documentos",
      to: "/administracion/portal-categorias",
      icon: <ReconciliationOutlined/>
    }
  ];

  const buttonData = {
    text: "Nueva Categoría",
    icon: <PlusCircleOutlined/>,
    to: () => history.push("/administracion/portal-categorias/nueva")
  }


  const sinDefinir = <i>-Sin definir-</i>

  const renderSimple = (prop) => prop || sinDefinir;

  const renderPagina = (prop) => prop ? paginas.find((item)=> item.value === prop).label : sinDefinir;

  const columns = [
    {
      title: "Página",
      width: 80,
      dataIndex: "pagina",
      key: "pagina",
      fixed: "left",
      render: renderPagina
    },
    {
      title: "Título",
      width: 80,
      dataIndex: "nombre",
      key: "nombre",
      ellipsis: true,
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Descripción",
      width: 150,
      dataIndex: "descripcion",
      key: "descripcion",
      render: renderSimple
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 40,
      render: (_, row) => (
        <DropdownOverlayButton
          options={[
            {
              name: "Ver detalle",
              icon: <EyeOutlined/>,
              onClick: () => modalInfo(row)
            },
            {
              name: "Editar información",
              icon: <EditOutlined/>,
              onClick: () => history.push(`portal-categorias/detalle?id=${row?.id}`),
              styleProps: {
                color: "#4495bb"
              }
            },
          ]}
        />
      ),
    },
  ];

  const ContentModal = ({row}) => {
    return (
      <div>
        <Descriptions
          bordered
          column={1}
          size="small"
          title="Información de la categoría"
        >
          <Descriptions.Item labelStyle={{"font-weight": "bold"}} label="ID">{row?.id || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"font-weight": "bold"}}
                             label="Título">{row?.nombre || sinDefinir}</Descriptions.Item>
          <Descriptions.Item labelStyle={{"font-weight": "bold"}}
                             label="Descripción">{row?.descripcion || sinDefinir}</Descriptions.Item>
        </Descriptions>
      </div>
    )
  }

  const modalInfo = (v) => {

    Modal.confirm({
      title: "",
      icon: null,
      content: <ContentModal row={v}/>,
      okText: "Cerrar",
      cancelButtonProps: {style: {display: 'none'}},
      okButtonProps: {primary: true},
      closable: true,
      width: 1000
    });
  };

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    setSearch(v);
    setSearchLoading(false);
  }, []);


  const extraParams = useMemo(() => {
    return {q: search}
  }, [search]);


  return (
    <SimpleTableLayout
      buttonData={buttonData}
      breadcrumbItems={breadcrumb}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearch}
      emptyText={emptyText}
    >
      <TablaComponent
        nameURL={'documento-portal-categoria'}
        columns={columns}
        order={'id-desc'}
        extraParams={extraParams}
      />
    </SimpleTableLayout>
  );
};