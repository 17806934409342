import React from "react";
import { Descriptions, Table,  Modal } from "antd";
import { BlockOutlined, EyeOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { DropdownOverlayButton } from "../../components";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import { useModels } from "../../hooks"
import { useHistory } from "react-router-dom";

const viewTitle = "Secciones";
const searchPlaceholder = "Buscar por nombre, etc.";
const emptyText = "No hay secciones registradas...";

const breadcrumbItems = [
  { 
    name: "Secciones", 
    to: "secciones", 
    icon: <BlockOutlined /> 
  }
];

export const SeccionLista = () => {
  const history = useHistory();
  const [pageSize, setPageSize] = React.useState(10)
  const [search, setSearch] = React.useState("")
  const [searchLoading, setSearchLoading] = React.useState(false)
  const [request, setRequest] = React.useState([])
  const [row, setRow] = React.useState(null)
  const [modalVisible, setModalVisible] = React.useState(false)

  const buttonData = {
    text: "Nueva Sección",
    icon: <PlusCircleOutlined />,
    to: () => history.push("/secciones/nueva")
  }

  const requestParams = React.useMemo(() => ({
    name: 'seccion',
    ordenar: 'id-asc',
    limite: pageSize,
    expand: 'ciudad'
  }),[pageSize])

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels(request)

  React.useEffect(() => { 
    setRequest(requestParams)
    return () => setRequest({})
  },[requestParams])

  const sinDefinir = <i>-Sin definir-</i>

  const renderSimple = (prop) => prop || sinDefinir;

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Ciudad",
      dataIndex: "ciudad",
      key: "ciudad",
      fixed: "left",
      render: prop => prop?.nombre || sinDefinir
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, row) => (
        <DropdownOverlayButton
          options={[
            {
              name: "Ver detalle",
              icon: <EyeOutlined />,
              onClick: () => modalInfo(row)
            },
          ]}
        />
      ),
    },
  ];

  const ContentModal = ({row}) => {
    return (
      <div>
        <Descriptions
          bordered
          column={1}
          size="small"
          title="Información de la Sección"
        >
          <Descriptions.Item label="ID" labelStyle={{ fontWeight: "bold" }}> { row?.id || sinDefinir } </Descriptions.Item>
          <Descriptions.Item label="Nombre" labelStyle={{ fontWeight: "bold" }}> { row?.nombre || sinDefinir } </Descriptions.Item>
          <Descriptions.Item label="Descripcion" labelStyle={{ fontWeight: "bold" }}> { row?.descripcion || sinDefinir } </Descriptions.Item>
          <Descriptions.Item label="Ciudad" labelStyle={{ fontWeight: "bold" }}> { row?.ciudad?.nombre || sinDefinir } </Descriptions.Item>
        </Descriptions>
      </div>
    )
  }

  const modalInfo = async v => {
    setRow(v)
    setModalVisible(true)
  };

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    setSearch(v);
    let params = {};
    if (v.length > 0) params = { ...params, buscar: v };
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
    setTimeout(() => setSearchLoading(false), 1500);
  },[refreshModels]);

  const onChangeCallback = React.useCallback(
    async ( page, size ) => {
      setPageSize(size);
      await refreshModels(true, { 
        ordenar: 'id-asc',
        pagina: page,
        limite: size,
        buscar: search
      })
  }, [refreshModels, search]);

  const getPageSizeOptions  = React.useMemo(() => {
    const options = [10, 20, 50, 100, 200, 500].filter(val => val <= modelsPage?.total);
    if (options[options.length - 1] !== modelsPage?.total) {
      options.push(modelsPage?.total);
    }
    return options
  },[modelsPage]);

  const config = React.useMemo(() => {
    if( modelsPage ) {
      let size = pageSize;
      return {        
        total: modelsPage.total,
        pageSize: modelsPage.limite,
        onShowSizeChange: ( _, newSize)  => ( size = newSize ),
        onChange: async (v) => await onChangeCallback(v, size),
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} secciones.`,
        locale: { items_per_page: '/ página' },
        pageSizeOptions: getPageSizeOptions,
        showSizeChanger: true
      }
    }
    return null;
  }, [onChangeCallback, pageSize, modelsPage, getPageSizeOptions]);

  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearch}
      emptyText={emptyText}
      children={
        <>
          <Table
            columns={columns}
            dataSource={models}
            loading={{
              spinning: modelsLoading,
              tip: 'Cargando información...',
            }}
            locale={{ emptyText }}
            pagination={config}
            rowKey="id"
            scroll={{ x: 'max-content' }}
          />
          <Modal
            onCancel={() => setModalVisible(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ type: 'primary' }}
            cancelText="Cerrar"
            visible={modalVisible}
            width={'50%'}
          >
            <ContentModal row={row}/>
          </Modal>
        </>
      }
    />
  );
};