import {AuditOutlined, CopyOutlined} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Divider,
  Row,
  Tooltip,
  Typography,
  notification,
  Select as SelectAntd,
} from "antd";
import locale from "antd/lib/date-picker/locale/es_ES";
import moment from "moment";
import React, {useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {
  EditorTexto,
  Select,
  TablaComponent,
  ViewLoading,
} from "../../components";
import {DefaultLayout} from "../../components/layouts";
import {useAuth, useModel, useModels, useQuery} from "../../hooks";
import StatusResponse from "../../services/statusResponse";
import {Respuesta} from "../../utilities/Respuesta";

const {Title} = Typography;
const {Search} = Input;

const permisoEditarActa = 26;
const permisoCrearActa = 24;
const permisoAbrirCerrarRecursos = 29;

const ActaDetalle = () => {
  const history = useHistory();
  const q = useQuery();

  const id = q.get("id");
  const editando = Boolean(id);

  const [form] = Form.useForm();

  const [request, setRequest] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [buscarValue, setBuscarValue] = useState("");
  const [cuerpo, setCuerpo] = useState("");
  const [request2, setRequest2] = useState({});

  const [models] = useModels(request2);

  const {user} = useAuth();

  const crear = user?.permisoExtra?.includes(permisoCrearActa);
  const editar = user?.permisoExtra?.includes(permisoEditarActa);
  const abrirCerrar = user?.permisoExtra?.includes(permisoAbrirCerrarRecursos);


  const requestParams = useMemo(
    () => ({
      name: "acta",
      id: id,
      expand: "actaFormato, mediaActa",
    }),
    [id]
  );

  const {model, modelLoading} = useModel(request);

  const breadcrumb = [
    {
      name: "Actas Sesión",
      to: "/actas-sesion",
      icon: <AuditOutlined/>,
    },
    {
      name: editando ? `${model?.titulo}` : "Nueva",
      to: "/actas-sesion",
      icon: <AuditOutlined/>,
    },
  ];

  const columns = [
    {
      title: "Copiar",
      width: 10,
      align: "center",
      render: (_, item) => (
        <Button
          icon={<CopyOutlined/>}
          onClick={() => {
            navigator.clipboard.writeText(item.nombre);
            notification.success({
              message: "Copiado!",
              description: `Se ha copiado ${item?.nombre} en el portapaleles`,
            });
          }}
        />
      ),
    },
    {
      title: "Nombre",
      key: "nombre",
      dataIndex: "nombre",
      render: (_, item) => (
        <Tooltip title={item?.descripcion} placement="left">
          {item?.nombre}
        </Tooltip>
      ),
    },
  ];

  const onSearch = (search) => {
    setBuscarValue(search);
  };

  const onFinish = async (values) => {
    try {
      setSaveLoading(true);

      const {fecha, cuerpo} = values;
      const _cuerpo = cuerpo.replace('<p class="ql-align-justify"><br></p><p class="ql-align-justify"><br></p>', '<p class="ql-align-justify"><br></p>');

      let body = {
        ...values,
        cuerpo: _cuerpo,
        fecha: fecha ? moment(fecha)?.format() : null,
        idActaFormato: values.idActaFormato.value,
      };

      if (editando) {
        body.id = id;
      }

      const res = await StatusResponse.post("acta/guardar", body);
      Respuesta(res);
      if (res?.status === 200) {
        history.push("/actas-sesion");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setRequest(requestParams);
    setRequest2({
      name: "acta-formato",
      limit: -1,
    });
    return () => {
      setRequest({});
      setRequest2({});
    };
  }, [requestParams]);

  useEffect(() => {
    if (editando && model) {
      console.log(model)
      form.setFieldsValue({
        ...model,
        fecha: model?.fecha ? moment(model?.fecha) : null,
      });

      if (model?.actaFormato?.cuerpo) {
        setCuerpo(model?.actaFormato?.cuerpo);
      }
    }
  }, [editando, form, model]);

  useEffect(() => {
    if (models && models.length > 0 && !editando) {
      console.log(models);
      console.log(form.getFieldValue("idActaFormato"));
      const formato = models.find((item) => item.id === form.getFieldValue("idActaFormato"));
      if (formato) {
        setCuerpo(formato?.cuerpo);
        form.setFieldsValue({
          cuerpo: formato?.cuerpo,
        });
      }
    }

  }, [editando, form, models]);

  if (modelLoading) return <ViewLoading/>;

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editando ? `Acta ${model?.titulo}` : "Nueva Acta de Sesión"}
    >
      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          idActaFormato: !editando && 2, // Acta de Sesión
        }}
      >
        <Row gutter={10}>
          <Col sm={12} md={8}>
            <Form.Item label="Fecha" name="fecha">
              <DatePicker
                style={{width: "100%"}}
                format="DD/MM/YYYY HH:mm"
                placeholder="Selecciona la fecha"
                locale={locale}
                showTime
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item label="Titulo" name="titulo">
              <Input/>
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item
              required
              name="idActaFormato"
              label="Formato"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione una formato",
                },
              ]}
            >
              <Select
                modelsParams={{name: "acta-formato", ordenar: "nombre-asc"}}
                showSearch
                valueProp={"id"}
                autoComplete={"off"}
                optionFilterProp="children"
                placeholder="Selecciona un Formato"
                allowClear
                labelInValue
                render={(_, row) => `${row?.nombre}`}
                onChange={(_, option) => {
                  setCuerpo(option?.cuerpo);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col sm={24} md={8}>
            <Button
              type="dashed"
              style={{
                backgroundColor: "lightgray",
              }}
              onClick={() => {
                form.setFieldsValue({
                  cuerpo: cuerpo,
                });
              }}
            >
              Iniciar Contenido
            </Button>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item label="Estatus" name="estatus">
              <SelectAntd
                placeholder="Selecciona un estatus"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={!abrirCerrar}
              >
                <SelectAntd.Option value="ABIERTO">ABIERTO</SelectAntd.Option>
                <SelectAntd.Option value="CERRADO">CERRADO</SelectAntd.Option>
              </SelectAntd>
            </Form.Item>
          </Col>
        </Row>
        <Divider my={10}/>
        <Row gutter={[10, 10]}>
          <Col
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 16}}
            lg={{span: 18}}
          >
            <Form.Item name="cuerpo">
              <EditorTexto/>
            </Form.Item>
          </Col>
          <Col
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 6}}
          >
            <Title level={4}>Listado de variables disponibles</Title>
            <Search
              placeholder="Buscar variable"
              onSearch={onSearch}
              enterButton
            />
            <Divider my={10}/>
            <TablaComponent
              columns={columns}
              scrollX="10vw"
              nameURL={"acta-variable"}
              extraParams={{buscar: buscarValue || "", ordenar: "nombre-asc"}}
            />
          </Col>
        </Row>
        <Divider my={10}/>
        <Row gutter={[16, 0]} style={{marginTop: "10px"}}>
          {((!editando && crear) || (editando && editar)) && (
            <Col sm={6}>
              <Form.Item>
                <Button htmlType="submit" loading={saveLoading} type="primary">
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </DefaultLayout>
  );
};

export default ActaDetalle;
