import React from "react";
import {Button, Col, Form, Input, Row, Select, Space, Tooltip} from "antd";
import {
  ClearOutlined,
  SearchOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {useAuth, useModels} from "../../../../hooks";
import {useHistory} from "react-router-dom";

const {OptGroup, Option} = Select;

export const Search = ({
                         disabledButtons,
                         form,
                         loading,
                         onClean,
                         onSearch,
                       }) => {
  const {user} = useAuth();
  const rol = user?.rol;
  const history = useHistory();
  const [requestArticulo, setRequestArticulo] = React.useState({});

  const [articulos, articulosLoading] = useModels(requestArticulo);

  React.useEffect(() => {
    setRequestArticulo({
      name: "articulo",
      ordenar: "id-asc",
      limite: -1,
      extraParams: {
        padres: 1,
      },
    });
    return () => setRequestArticulo({});
  }, []);

  return (
    <Form form={form} layout="vertical">
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Form.Item label="Artículo" name="idArticulo">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                if (option.children) {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }
                return false;
              }}
              listItemHeight={10}
              listHeight={250}
              loading={articulosLoading}
              placeholder="Selecciona un artículo padre"
            >
              <OptGroup label="Leyes y Artículos">
                {articulos
                  ? articulos
                    .filter((f) => !f?.ambito.includes("Informativo Portal"))
                    .filter(item => item?.ambito === user?.ente?.ambito)
                    .map((m, i) => (
                      <Option
                        key={`leyes-articulos-${i}`}
                        value={m?.id}
                        item={m}
                      >{`${m.ambito} - ${m.titulo}`}</Option>
                    ))
                  : []}
              </OptGroup>
              {rol !== "sindicato" && (
                <OptGroup label="Información Portal">
                  {articulos
                    ? articulos
                      .filter((f) => {
                        if (!f?.ambito.includes("Informativo Portal")) {
                          return false;
                        }
                        if (rol === "municipio") {
                          return !f?.titulo.includes("Pleno");
                        }
                        return true;
                      })
                      .map((m, i) => (
                        <Option
                          key={`informativo-portal-${i}`}
                          value={m?.id}
                          item={m}
                        >{`${m.ambito} - ${m.titulo}`}</Option>
                      ))
                    : []}
                </OptGroup>
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Form.Item label="Búsqueda" name="buscar">
            <Input placeholder="Buscar por Título, Ley, Descripción, etc..."/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
          <Space justify="space-between" style={{marginTop: ".5rem"}}>
            <Tooltip title="Buscar">
              <Button
                disabled={disabledButtons}
                icon={<SearchOutlined/>}
                loading={loading}
                onClick={onSearch}
                shape="circle"
                style={{marginTop: "1.5rem"}}
                type="primary"
              />
            </Tooltip>
            <Tooltip title="Limpiar">
              <Button
                disabled={disabledButtons}
                icon={<ClearOutlined/>}
                onClick={onClean}
                shape="circle"
                style={{marginTop: "1.5rem"}}
                type="default"
              />
            </Tooltip>
          </Space>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          style={{textAlign: "right"}}
        >
          <Button
            disabled={disabledButtons}
            icon={<PlusCircleOutlined/>}
            onClick={() => history.push("/administracion/articulos/nueva")}
            style={{marginTop: "1.5rem"}}
            type="default"
          >
            Nueva Artículo
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
