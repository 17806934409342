import React from "react";
import EditorToolbar, { modules, formats } from "./EditorToolbar";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditorTexto = ({
  ...props
}) => {

  return (
    <>
      <EditorToolbar />
      <ReactQuill
        {...props}
        style={{ display: "block", width: "100%"}}
        theme="snow"
        modules={modules}
        formats={formats}
      />
    </>
  );
};



export default EditorTexto;
