import React, { Suspense } from "react";
import { useAlert, useApp } from "../hooks";
import { Alert } from "antd";

const PrivateRouter = React.lazy(() => import("./PrivateRouter"));
const PublicRouter = React.lazy(() => import("./PublicRouter"));

const AppRouting = () => {
  const { open, severity, message } = useAlert();
  const { token } = useApp();

  const AlertMemo = React.useMemo(
    () => open && <Alert type={severity} message={message} closable showIcon />,
    [open, severity, message]
  );

  return (
    <>
      {AlertMemo}
      {
        token ? (
          <Suspense fallback={null}>
            <PrivateRouter />
          </Suspense>
        )
      : (
          <Suspense fallback={null}>
            <PublicRouter />
          </Suspense>
        )
      }
    </>
  );
};

export default AppRouting;
