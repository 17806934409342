import React, { useCallback, useEffect, useMemo, useState } from "react";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import TablaComponent from "../../components/TablaComponent";
import { ActionsButton } from "../../components";
import { useHistory, Link } from "react-router-dom";
import { Col, Modal, Row, Tag, Tooltip } from "antd";
import {
  AuditOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import StatusResponse from "../../services/statusResponse";
import moment from "moment";
import { useAuth, useModel, useModels, useQuery } from "../../hooks";
import CheckableTag from "antd/lib/tag/CheckableTag";

const ActaRecurso = () => {
  const q = useQuery();
  const { user } = useAuth();
  const ver = user?.permisoExtra?.includes(27);
  const idActa = q.get("idActa");
  const rolAdmin = user?.rol === "admin";
  const tablaRef = React.useRef(null);

  const history = useHistory();

  const [buscar, setBuscar] = React.useState("");
  const [request, setRequest] = React.useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [requestActa, setRequestActa] = React.useState({});

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const breadcrumb = [
    {
      name: "Actas",
      to: "/actas-sesion",
      icon: <AuditOutlined />,
    },
    {
      name: "Recursos y/o Denuncias",
      to: "/actas-sesion/acta-recursos?idActa=" + idActa,
      icon: <AuditOutlined />,
    },
  ];

  const ponenciasParams = React.useMemo(
    () => ({ name: "ponencia", ordenar: "nombre-asc" }),
    []
  );

  const [models, , , , ,] = useModels(request);

  const actaParams = useMemo(
    () => ({
      name: "acta",
      id: idActa,
      expand: "actaFormato",
    }),
    [idActa]
  );

  const { model } = useModel(requestActa);

  const buttonData = ver
    ? {
      text: "Nuevo Recurso y/o Denuncia",
      icon: <PlusCircleOutlined />,
      to: () => history.push("acta-recursos/agregar?idActa=" + idActa),
      size1: 14,
      size2: 8,
      disabled: model?.estatus === "CERRADO",
    }
    : {};

  const onSearch = (v) => {
    setBuscar(v);
  };

  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar el expediente "${valor?.numeroExpediente}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("acta-caso/eliminar", {
          id: valor.id,
        });
        return new Promise((resolve, reject) => {
          try {
            if (valor.id > 0) {
              if (res && res.status === 200) {
                tablaRef?.current?.refresh();
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        });
      },
    });
  };

  const columnasRecurso = [
    {
      title: "Acciones",
      key: "id",
      dataIndex: "id",
      render: (_, item) => (
        <ActionsButton
          options={[
            {
              name: "Editar",
              onClick: () =>
                history.push(
                  `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
                ),
              styleProps: {
                display:
                  item?.ponencia?.idPonencia === user?.idPonencia || rolAdmin
                    ? "block"
                    : "none",
              },
            },
            {
              name: "Eliminar",
              onClick: () => modalEliminar(item),
              danger: true,
              styleProps: {
                color: "#f5222d",
                display:
                  item?.ponencia?.idPonencia === user?.idPonencia || rolAdmin
                    ? "block"
                    : "none",
              },
            },
          ]}
        />
      ),
    },
    {
      title: "No. Expediente",
      key: "numeroExpediente",
      dataIndex: "numeroExpediente",
      render: (_, item) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
            );
          }}
        >
          {item?.tipo === "RECURSO"
            ? `ISTAI-RR-${item?.numeroExpediente}`
            : `ISTAI-DI-${item?.numeroExpediente}`}
        </Link>
      ),
    },
    {
      title: "Tipo de Recurso",
      key: "tipo",
      dataIndex: "tipo",
      render: (_, item) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
            );
          }}
        >
          <Tooltip title={item?.tipo}>
            <Tag color={item?.tipo === "RECURSO" ? "#0070c1" : "#6f2fa0"}>
              {item?.tipo}
            </Tag>
          </Tooltip>
        </Link>
      ),
    },
    {
      title: "Ponencia",
      key: "idPonencia",
      dataIndex: "idPonencia",

      render: (_, item) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
            );
          }}
        >
          <Tooltip title={item?.ponencia?.nombre}>
            <Tag color="#00736c">{item?.ponencia?.clave}</Tag>
          </Tooltip>
        </Link>
      ),
    },
    {
      title: "Sujeto Obligado",
      key: "sujetoObligado",
      dataIndex: "sujetoObligado",
      render: (_, item) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
            );
          }}
        >
          {item?.tipo === "RECURSO"
            ? item?.sujetoObligado
            : item?.sujetoDenunciado}
        </Link>
      ),
    },
    {
      title: "Recurrente / Denunciante",
      key: "recurrente",
      dataIndex: "recurrente",
      render: (_, item) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
            );
          }}
        >
          {item?.tipo === "RECURSO" ? item?.recurrente : item?.denunciante}
        </Link>
      ),
    },
    {
      title: "Fecha de Solicitud",
      key: "fechaSolicitud",
      dataIndex: "fechaSolicitud",
      render: (_, item) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
            );
          }}
        >
          {moment(item?.fechaSolicitud)?.format("DD/MM/YYYY")}
        </Link>
      ),
    },
    {
      title: "Solicita / Denuncia",
      key: "solicita",
      dataIndex: "solicita",
      render: (_, item) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
            );
          }}
        >
          <Tooltip title={item?.solicita}>
            {item?.tipo === "RECURSO"
              ? `${item?.solicita?.substring(0, 40)}...`
              : `${item?.denuncia?.substring(0, 40)}...`}
          </Tooltip>
        </Link>
      ),
    },
    {
      title: "Fecha del Recurso",
      key: "fechaRecurso",
      dataIndex: "fechaRecurso",
      render: (_, item) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
            );
          }}
        >
          {item.tipo === "RECURSO"
            ? moment(item?.fechaRecurso)?.format("DD/MM/YYYY")
            : "No aplica"}
        </Link>
      ),
    },
    {
      title: "Litis",
      key: "litis",
      dataIndex: "litis",
      render: (_, item) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
            );
          }}
        >
          <Tooltip title={item?.litis}>
            {`${item?.litis?.substring(0, 40)}...`}
          </Tooltip>
        </Link>
      ),
    },
    {
      title: "Informe Justificado",
      key: "respuesta",
      dataIndex: "respuesta",
      render: (_, item) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
            );
          }}
        >
          <Tooltip title={item?.respuesta}>
            {item?.respuesta
              ? `${item?.respuesta?.substring(0, 40)}...`
              : ""}
          </Tooltip>
        </Link>
      ),
    },
    {
      title: "Sentido",
      key: "sentido",
      dataIndex: "sentido",
      render: (_, item) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
            );
          }}
        >
          <Tooltip title={item?.sentido}>
            {`${item?.sentido?.substring(0, 40)}...`}
          </Tooltip>
        </Link>
      ),
    },
    {
      title: "Informe Verificación",
      key: "informeVerificacion",
      dataIndex: "informeVerificacion",
      render: (_, item) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/actas-sesion/acta-recursos/detalle?idActa=${item?.idActa}&e=${item?.id}`
            );
          }}
        >
          {item?.tipo === "RECURSO"
            ? "No aplica"
            : <Tooltip title={item?.informeVerificacion}>
              {`${item?.informeVerificacion?.substring(0, 40)}...`}
            </Tooltip>}
        </Link>
      ),
    },
  ];

  const localStoragePonencias = useCallback(() => {
    localStorage.setItem("ponencias", JSON.stringify(selectedTags));
  }, [selectedTags]);

  const localStoragePonenciasGet = () => {
    const ponencias = JSON.parse(localStorage.getItem("ponencias"));
    if (ponencias) {
      setSelectedTags(ponencias);
    }
  };

  useEffect(() => {
    localStoragePonenciasGet();
  }, []);

  useEffect(() => {
    localStoragePonencias();
  }, [localStoragePonencias]);

  useEffect(() => {
    setRequest(ponenciasParams);
    return () => setRequest({});
  }, [ponenciasParams]);

  useEffect(() => {
    setRequestActa(actaParams);
    return () => setRequestActa({});
  }, [actaParams]);
  return (
    <SimpleTableLayout
      buttonData={buttonData}
      breadcrumbItems={breadcrumb}
      onSearchClicked={onSearch}
      subtitle={model?.titulo ? `${model?.titulo}` : ""}
    >
      <Row style={{ margin: "10px" }}>
        {models.map((item, index) => (
          <Col key={index}>
            <CheckableTag
              style={{ border: "1px solid black" }}
              key={index}
              checked={selectedTags.includes(item?.idPonencia)}
              onChange={(checked) => handleChange(item?.idPonencia, checked)}
            >
              {item?.clave}
            </CheckableTag>
          </Col>
        ))}
      </Row>
      <TablaComponent
        nameURL={"acta-caso"}
        columns={columnasRecurso}
        order={"numeroExpediente-asc"}
        innerRef={tablaRef}
        expand="ponencia"
        extraParams={{ q: buscar, ponencias: selectedTags, idActa: idActa }}
      />
    </SimpleTableLayout>
  );
};

export default ActaRecurso;
